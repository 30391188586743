import React from 'react';
import { Link } from 'react-router-dom';
import { BrowserLink } from '../enums';
import { login } from '../services';
import { GlobalContext } from '../services/context';

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    error: false,
    close: false,
  };

  static contextType = GlobalContext;

  componentDidMount() {
    document.body.style.position = 'fixed';
  }

  update = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      error: false,
    });
  };

  close = () => {
    const {
      history: { location },
    } = this.props;
    let redirectUrl = '/';
    const redirectTo = location.state?.redirectTo ?? undefined;
    const checkout = location.state?.checkout ?? null;
    if (redirectTo) {
      redirectUrl = redirectTo;
    }
    let routeProps = {
      pathname: redirectUrl,
    }
    if (checkout) {
      routeProps.search = "?" + new URLSearchParams({ checkout }).toString()
    }
    document.body.style.position = '';
    this.props.history.push(routeProps);
  };

  componentWillUnmount() {
    document.body.style.position = '';
  }

  submit = (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    const user = { email, password };
    login(user, 'vrlu')
      .then((res) => {
        const { user } = res.data;
        this.context.login(user);
        this.close();
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
        this.setState({ error: true });
      });
  };

  render() {
    const { email, password, error, close } = this.state;
    const slide = this.props.location.state && this.props.location.state.slide;
    return (
      <div className={'form-screen' + (slide ? ' slide-in' : '') + (close ? ' close' : '')}>
        <div className="form">
          <i className="form-logo in-form" onClick={this.close} />
          <div className="form-navigation">
            <span className="wide-screen">Don't have an account?</span>
            <Link to={BrowserLink.signUp}>Sign Up</Link>
          </div>
          <div className="form-title">Log in</div>
          <div className="form-subtitle">
            You’re just moments away from immersing yourself in all out virtual reality.
          </div>
          <form onSubmit={this.submit}>
            <label className="form-label">
              Email
              <input type="email" name="email" value={email} onChange={this.update} />
            </label>
            <label className="form-label">
              Password
              <input type="password" name="password" value={password} onChange={this.update} />
            </label>
            <div className="server-error">{error && <span>Invalid Email or Password</span>}</div>
            <button type="submit" className="button submit-button">
              Log in with VRLU
            </button>
          </form>
          <div className="or-container">
            <div className="or">Or</div>
          </div>
          <Link to={BrowserLink.gps} className="button gps-button">
            <i className="gps-logo" />
            <span>Log in with GPS</span>
          </Link>
        </div>
        <div className="form-background login-background">
          <i className="form-logo" onClick={this.close} />
          <div className="form-copy-title">Welcome to learning.</div>
          <div className="form-copy">
            Join the masters, thousands of life-long learners, and level up your reality today.
          </div>
        </div>
      </div>
    );
  }
}

export default Login;

import React from 'react';
// import { BrowserLink } from '../enums';
// import { useHistory } from 'react-router-dom';
// import { useStripe } from '@stripe/react-stripe-js';
// import { GlobalContext } from '../services/context';
// import { purchaseCourse } from '../services/api';

const Dive = ( props ) => {
  const { dive } = props;
  // const [isRedirecting, setIsRedirecting] = React.useState(false);
  // const stripe = useStripe();
  // const history = useHistory();
  // const context = React.useContext(GlobalContext);

  // const sendToCheckout = (event) => {
  //   event.stopPropagation();
  //   setIsRedirecting(true);
  //   if (!context.currentUser) {
  //     history.push({
  //       pathname: BrowserLink.login,
  //       state: { experience: experience, slide: true },
  //     });
  //   } else {
  //     purchaseCourse(experience.uuid)
  //       .then((res) => {
  //         stripe.redirectToCheckout({ sessionId: res.data.id });
  //       })
  //       .catch((err) => {
  //         setIsRedirecting(false);
  //         // TODO: HANDLE ERROR;
  //         console.log(err);
  //       });
  //   }
  // };

  return (
    <div role="a" className="episode card">
      <img src={dive.landscape_thumbnail_url} />
      <div className="card-overlay" />
      <div className="card-details">
        <div className="card-title">{dive.title}</div>
        <div className="card-description">{dive.artist}</div>
        {/* {!karaoke.is_unlocked && (
          <button className="get-button-small" disabled={isRedirecting} onClick={sendToCheckout}>
            Get
          </button>
        )} */}
      </div>
    </div>
  );
};
export default Dive;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { BrowserLink } from '../enums';
import MastersLeaderImg from '../../../../assets/images/masters-leader.jpg';
import KpopLeaderImg from '../../../../assets/images/kpop-leader.jpg';
import ConiTVLeaderImg from '../../../../assets/images/conitv-leader.jpg';

const Home = () => {
  const history = useHistory();

  const experiences = [
    {
      to: BrowserLink.mastersAcademy,
      img: MastersLeaderImg
    },
    {
      to: BrowserLink.kpop,
      img: KpopLeaderImg
    },
    {
      to: BrowserLink.coniTV,
      img: ConiTVLeaderImg
    },
  ]

  return (
    <div className="home">
      <div className="welcome-background">
        <div className="vertical-gradient" />
        <div className="horizontal-gradient" />
      </div>
      <div className="welcome">
        <div className="page-content">
          <div className="light-text">Virtual Reality unleashed</div>
          <div className="page-title">Leading VR education and entertainment platform.</div>
          {/* {context.language === Language.Korean && (
            <div className="subtitle">
              각 분야 최고의 마스터에게 삶의 철학과 지혜를 배우고, 분야별 전문 지식과 노하우를 전수받을 수 있는 실감
              체험형 자기계발 콘텐츠
            </div>
          )} */}
        </div>
      </div>
      <div className="page-content">
        <div className="section-title">Watch the VRLU Trailer</div>
      </div>
      <div className="trailer-container">
        <iframe src="https://player.vimeo.com/video/466062423" />
      </div>
      <div className="our-experiences-container">
        <div className="our-experiences-container-bg"></div>
        <div className="our-experiences-title">
          <div className="section-title">
          Our experiences
          </div>
        </div>
        <div className="our-experiences-card-container-outer">
          <div className="our-experiences-card-container">
          {experiences.map((exp, idx) => (
            <div role="a" key={idx} className="our-experiences-card" onClick={() => history.push(exp.to)}>
              <img src={exp.img} />
            </div>
          ))}
          </div>
        </div>
      </div>
      {/* <div className="page-content"> */}
        <div className="partners">OUR PARTNERS</div>
        <div className="press">
          <i className="gps" />
          <i className="mm" />
          <i className="pico" />
          <i className="sbs" />
          <i className="unity" />
          <i className="ocon" />
          <i className="albus" />
          <i className="ufo" />
          <i className="kt" />
        </div>
      {/* </div> */}
    </div>
  );
};

export default Home;

// const Summary = props => (
//   <div className='summary-background'>
//     <div className='summary-overlay'>
//       <div className='page-content'>
//         <div className='section-title'>Everything You Get</div>
//         <div className='subtitle'>Immersion like you've never seen it before</div>
//         <div className='specs'>
//           <div className='spec'>
//             <div className='spec-title'>20+ MASTERS</div>
//             <div className='subtitle'>Learn from the best</div>
//           </div>
//           <div className='spec'>
//             <div className='spec-title'>150+ CLASSES</div>
//             <div className='subtitle'>In full 360 degree immersion</div>
//           </div>
//           <div className='spec'>
//             <div className='spec-title small'>DAILY EXCLUSIVE CONTENT</div>
//             <div className='subtitle'>Coming soon</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// )

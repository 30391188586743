import React from 'react';

const Success = ({ history }) => {
  const sendToHome = () => {
    history.push('/');
  };

  return (
    <>
      <div className="success-background">
        <div className="success-container">
          <div className="success-checkmark">
            <div className="check-icon">
              <span className="icon-line line-tip"></span>
              <span className="icon-line line-long"></span>
              <div className="icon-circle icon-circle-bg"></div>
              <div className="icon-fix"></div>
            </div>
          </div>
          <div className="success-title dark">Success!</div>
          <div className="success-subtitle dark">This experience is now available in your VRLU app.</div>
          <button className="success-button" onClick={sendToHome}>
            {' '}
            Got it!{' '}
          </button>
        </div>
      </div>
    </>
  );
};

export default Success;

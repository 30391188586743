import React from 'react';
import { GlobalContext } from '../services/context';
import Series from './Series';

const SeriesIndex = ({ isProfile }) => {
  const context = React.useContext(GlobalContext);
  const [series, setSeries] = React.useState([]);
  
  const loadSeries = async () => {
    let s = await context.getSeries();
    if (!s) return;
    if (isProfile) {
      s = s.filter( s => s.is_unlocked === true );
    }
    setSeries(s);
  }

  React.useEffect(() => {
    loadSeries();
  }, []);
  
  function buildHeader() {
    if(isProfile && series.length > 0) {
      return <div className="subtitle page-content">ConiTV Series</div>
    }
    return null
  }

  return (
    <>
    {buildHeader()}
    <div className="card-index">
      {series
        .sort((a, b) => a.featured_sort_order - b.featured_sort_order)
        .map((s) => (
          <Series series={s} key={s.uuid} />
        ))}
    </div>
    </>
  );
};

export default SeriesIndex;

import React from 'react';
import { GlobalContext } from '../services/context';
import Movie from './Movie';

const MoviesIndex = ({ isProfile }) => {
  const context = React.useContext(GlobalContext);
  const [movies, setMovies] = React.useState([]);
  
  const loadMovies = async () => {
    let m = await context.getMovies();
    if (!m) return;
    if (isProfile) {
      m = m.filter( m => m.is_unlocked === true );
    }
    setMovies(m);
  }

  React.useEffect(() => {
    loadMovies();
  }, []);
  
  function buildHeader() {
    if(isProfile && movies.length > 0) {
      return <div className="subtitle page-content">ConiTV Movies</div>
    }
    return null
  }
  
  return (
    <>
    {buildHeader()}
    <div className="card-index">
      {movies
        .sort((a, b) => a.featured_sort_order - b.featured_sort_order)
        .map((m) => (
          <Movie movie={m} key={m.uuid} />
        ))}
    </div>
    </>
  );
};

export default MoviesIndex;

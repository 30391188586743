import React from 'react';
import { Link } from 'react-router-dom';
import { BrowserLink } from '../enums';
import { GlobalContext } from '../services/context';
import ProfileMenu from './ProfileMenu';

class Header extends React.Component {
  state = {
    loginOpen: false,
    blur: false,
    menuOpen: false,
  };

  static contextType = GlobalContext;

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
  }

  openLogin = () => this.setState({ loginOpen: true });

  closeLogin = () => this.setState({ loginOpen: false });

  openMenu = () => {
    const { innerWidth } = window
    // 710 is the breakpoint at which the header nav is hidden in _style.scss
    if (innerWidth < 710) {
      this.context.showDrawer(true  )
    } else {
      this.setState({ menuOpen: true });
      document.addEventListener('click', this.onClick);
    }
  };

  closeMenu = () => this.setState({ menuOpen: false });

  onClick = (event) => {
    event.preventDefault();
    if (!this.profileMenu || !this.profileMenu.contains(event.target)) {
      this.closeMenu();
      document.removeEventListener('click', this.onClick);
    }
  };

  onScroll = () => {
    const { blur } = this.state;
    if (blur && document.documentElement.scrollTop === 0) {
      this.setState({ blur: false });
    } else if (!blur && document.documentElement.scrollTop > 0) {
      this.setState({ blur: true });
    }
  };

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
    document.removeEventListener('click', this.onClick);
  }

  profileMenuRef = (node) => (this.profileMenu = node);

  render() {
    const { blur, menuOpen } = this.state;
    const { currentUser, logout } = this.context;
    return (
      <div className={blur ? 'header blur' : 'header'}>
        <div className="page-content header-content">
          <div>
            <Link to="/">
              <i className="header-logo" />
            </Link>
          </div>
          <div className="header-content">
            <Link className="header-link" to={BrowserLink.mastersAcademy}>
              Master Academy
            </Link>
            <Link className="header-link" to={BrowserLink.kpop}>
              K-POP VRoom
            </Link>
            <Link className="header-link" to={BrowserLink.coniTV}>
              ConiTV
            </Link>
            {!currentUser && (
              <Link
                to={{
                  pathname: BrowserLink.login,
                  state: { slide: true },
                }}
                className="open-login-button"
              >
                Log In
              </Link>
            )}
            <div className="profile-menu-container">
              <i className="profile-logo" onClick={this.openMenu} />
              {menuOpen && (
                <ProfileMenu
                  profileMenuRef={this.profileMenuRef}
                  name={currentUser ? currentUser.name : ''}
                  logout={logout}
                  close={this.closeMenu}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;

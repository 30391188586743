import React from 'react';
import { GlobalContext } from '../services/context';
import Dive from './Dive';

const DiveIndex = () => {
  const context = React.useContext(GlobalContext);
  const [karaokes, setKaraokes] = React.useState([]);
  
  const loadKaraokes = async () => {
    let k = await context.getKaraokes();
    if(!k) return;
    setKaraokes(k.filter( k => k.category === 'diva' ));
  };

  React.useEffect(() => {
    loadKaraokes();
  }, []);
  
  return (
    <div className="card-index episode-index">
      {karaokes
        .sort((a, b) => a.featured_sort_order - b.featured_sort_order)
        .map((k) => (
          <Dive dive={k} key={k.uuid} />
        ))}
    </div>
  );
};

export default DiveIndex;

import React from 'react';

const EpisodeSeries = ({ episode }) => (
  <div className="episode card">
    <img src={episode.landscape_thumbnail_url} />
    <div className="card-overlay" />
    <div className="card-details">
      <div className="card-title">{episode.title}</div>
      <div className="card-description">{episode.subtitle}</div>
    </div>
  </div>
);

export default EpisodeSeries;

import React from 'react';

const Privacy = () => (
  <div>
    <h1>Privacy Policy</h1>
    <p>Date of Last Revision: January 15, 2020</p>
    <h2>Our Policy</h2>
    <p>
      This Privacy Policy describes the data protection practices of VRLU <b>(“VRLU,” “we,” “us,” or “our”)</b>. This
      Privacy Policy applies to information that we collect and use about you when you access or use the VRLU website,
      mobile application, or other online or mobile service that links to or otherwise presents this Privacy Policy to
      you. We refer to these products and services collectively as the <b>“Services.”</b>
    </p>
    <p>
      <b>
        PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW WE HANDLE YOUR INFORMATION. IF YOU DO NOT AGREE TO
        THIS PRIVACY POLICY, PLEASE DO NOT USE THE SERVICES.
      </b>
    </p>
    <p>This Privacy Policy contains the following sections:</p>
    <h2>INFORMATION WE COLLECT</h2>
    <p>
      We collect information about you through the means discussed below. Please note that we need certain types of
      information so that we can provide the Services to you. If you do not provide us with such information, or ask us
      to delete it, you may no longer be able to access or use our Services.
    </p>
    <h2>1. Information You Provide to Us</h2>
    <p>
      We collect a variety of information that you provide directly to us. For example, we collect information from you
      through:
    </p>
    <ul>
      <li>The Services you use or processing your orders</li>
      <li>Requests or questions you submit to us via online forms, email, or otherwise</li>
      <li>Your participation in sweepstakes, contests, or surveys</li>
      <li>Any reviews that you submit about the Services</li>
      <li>Account registration and administration of your account</li>
      <li>Uploads or posts to the Services</li>
      <li>Requests for customer support and technical assistance</li>
    </ul>
    <h3>Information about you.</h3>
    <p>
      While parts of the Services may not require you to provide any information that can directly identify you by name
      (such as if you choose to browse the website without logging in), the specific types of information we collect
      will depend upon the Services you use, how you use them, and the information you choose to provide. The types of
      data we collect directly from you includes:
    </p>
    <ul>
      <li>Email address</li>
      <li>Name, if you choose to provide it</li>
      <li>Log-in credentials, if you create a VRLU account</li>
      <li>
        Billing information, such as shipping address of a gift card recipient, credit or debit card number,
        verification number, and expiration date
      </li>
      <li>Information about purchases or other transactions with us</li>
      <li>Information about your customer service interactions with us</li>
      <li>
        Demographic information such as your gender or other information you choose to provide as part of your VRLU
        profile
      </li>
      <li>
        User-generated content you provide to us, such as when you comment on content on the Services, respond to a
        survey request, review a class, or participate in the public forums
      </li>
      <li>Classes in which you enroll</li>
      <li>Any other information you choose to directly provide to us in connection with your use of the Services</li>
    </ul>
    <h3>Information about others.</h3>
    <p>
      If you request that your purchase be provided to someone other than yourself (such as a gift recipient), we use
      the information you provide about the other person to fulfill the shipment.
    </p>
    <h2>2. Information We Collect Through Automated Means</h2>
    <p>
      When you use our Services, we collect certain information as described in this Section. As discussed further
      below, we and our service providers (which are third party companies that work on our behalf) may use a variety of
      technologies, including cookies and similar tools, to assist in collecting this information.
    </p>
    <h3>Websites.</h3>
    <p>
      When you use our website, we collect and analyze information such as your IP address, browser types, browser
      language, operating system, software and hardware attributes (including device IDs) referring and exit pages and
      URLs, the number of clicks, pages viewed and the order of those pages, date and time of use, content watched,
      total minutes watched, error logs, and other similar information about how you use the website.
    </p>
    <h3>Mobile Applications.</h3>
    <p>
      When you use a VRLU mobile application or software ("app"), we automatically receive certain information about the
      mobile phone, tablet, or computer used to access the app, including device identifiers, IP address, operating
      system, version, Internet service provider, browser type, domain name and other similar information, whether and
      when you update the app, date and time of use, content watched, total minutes watched, error logs, and other
      similar information about how you use the app.
    </p>
    <h3>Location Information.</h3>
    <p>
      When you use the Services, we and our service providers may automatically collect general location information
      (e.g., IP address, city/state and or postal code associated with an IP address) from your computer or mobile
      device.
    </p>
    <h2>3. Information We Collect From Social Media and Other Content Platforms</h2>
    <p>
      If you access the Services through a third-party connection or log-in (e.g., through a social network like
      Facebook or Twitter), you may allow us to have access to and store certain information from your social network
      profile. This can include your name, gender, profile picture, your “likes” and check-ins, and your list of
      friends, depending on your settings on such services. If you do not wish to have this information shared, do not
      use a social networking connection to access the Services. For a description of how social networking sites handle
      your information, please refer to their privacy policies and terms of use, which may permit you to modify your
      privacy settings. You may also have the option of posting your Services activities to Social Networking Services
      when you access content through the Services (for example, you may post to Facebook that you enrolled in a class
      on the Service); you acknowledge that if you choose to use this feature, your friends, followers and subscribers
      on any Social Networking Services you have enabled will be able to view such activity.
    </p>
    <h2>4. Information We Collect from Others</h2>
    <p>
      We may receive additional information such as demographic and statistical information from third parties, such as
      business partners, marketers, researchers, analysts, and other parties that we may attribute to you based on your
      assignment to certain statistical groups. We use this information to supplement the information that we collect
      directly from you in order to derive your possible interests and to provide more relevant experiences for you and
      improve our products, analytics, and advertising.
    </p>
    <h2>HOW WE USE YOUR INFORMATION</h2>
    <p>
      We, or our service providers, use your information for various purposes depending on the types of information we
      have collected from and about you, in order to:
    </p>
    <ul>
      <li>Complete a purchase or provide the Services you have requested, including invoicing and accounting</li>
      <li>
        Respond to your request for information and provide you with more effective and efficient customer service
      </li>
      <li>Provide you with updates and information about classes in which you have enrolled</li>
      <li>
        Contact you by email, postal mail, or phone regarding VRLU and third-party products, services, surveys, research
        studies, promotions, special events and other subjects that we think may be of interest to you
      </li>
      <li>Customize the advertising and content you see on the Services</li>
      <li>
        Help us better understand your interests and needs, and improve the Services, including through research and
        reports, and test and create new products, features, and services
      </li>
      <li>Secure our websites and applications, and resolve app crashes and other issues being reported</li>
      <li>
        Comply with any procedures, laws, and regulations which apply to us where it is necessary for our legitimate
        interests or the legitimate interests of others
      </li>
      <li>
        Establish, exercise, or defend our legal rights where it is necessary for our legitimate interests or the
        legitimate interests of others
      </li>
    </ul>
    <h3>Combined Information.</h3>
    <p>
      For the purposes discussed in this Privacy Policy, we may combine the information that we collect through the
      Services with information that we receive from other sources, both online and offline, and use such combined
      information in accordance with this Privacy Policy.
    </p>
    <h3>Aggregate/Anonymous Data.</h3>
    <p>
      We may aggregate and/or anonymize any information collected through the Services so that such information can no
      longer be linked to you or your device. We may use such information for any purpose, including without limitation
      for research and marketing purposes, and may also share such data with any third parties, including advertisers,
      promotional partners, and sponsors.
    </p>
    <h2>COOKIES AND SIMILAR TECHNOLOGIES</h2>
    <p>
      To collect the information in the “Information We Collect Through Automated Means” section above, we and our
      service providers use Internet server logs, cookies, tracking pixels, and other similar tracking technologies. We
      use these technologies in order to offer you a more tailored experience in the future, by understanding and
      remembering your particular browsing preferences. Cookies are small text files that are placed on your computer or
      mobile device when you visit a site that enable us to: (i) recognize your computer; (ii) store your preferences
      and settings; (iii) understand the web pages of the Services you have visited; (iv) enhance your user experience
      by delivering and measuring the effectiveness of content and advertising tailored to your interests; (v) perform
      searches and analytics; and (vi) assist with security and administrative functions. Some cookies are placed in
      your browser cache while those associated with Flash technologies are stored with your Adobe Flash Player files.
    </p>
    <p>
      As we adopt additional technologies, we may also gather information through other methods. Please note that you
      can change your settings to notify you when a cookie is being set or updated or to block cookies altogether.
      Please consult the “Help” section of your browser for more information (e.g., Internet Explorer; Google Chrome;
      Mozilla Firefox; or Apple Safari). You can also manage the use of Flash technologies, including flash cookies and
      local storage objects, with the Flash management tools available at Adobe's website. Please note that by blocking,
      disabling, or managing any or all cookies, you may not have access to certain features or offerings of the
      Services.
    </p>
    <h2>ONLINE ANALYTICS AND ADVERTISING</h2>
    <h2>1. Analytics</h2>
    <p>
      We may use third-party web analytics services (such as those of Google Analytics) on our Services to collect and
      analyze usage information through cookies and similar tools; engage in auditing, research, or reporting; assist
      with fraud prevention; and provide certain features to you. To prevent Google Analytics from using your
      information for analytics, you may install the Google Analytics Opt-out Browser Add-on by{' '}
      <a href="https://tools.google.com/dlpage/gaoptout">clicking here</a>.
    </p>
    <p>
      If you receive email from us, we may use certain analytics tools, such as clear GIFs to capture data such as when
      you open our message or click on any links or banners our email contains. This data allows us to gauge the
      effectiveness of our communications and marketing campaigns.
    </p>
    <h2>2. Online Advertising</h2>
    <p>
      The Services may integrate third-party advertising technologies that allow for the delivery of relevant content
      and advertising on the Services, as well as on other websites you visit and other applications you use. The ads
      may be based on various factors such as the content of the page you are visiting, information you enter such as
      your age and gender, your searches, demographic data, user-generated content, and other information we collect
      from you. These ads may be based on your current activity or your activity over time and across other websites and
      online services and may be tailored to your interests.
    </p>
    <p>
      Third parties may also place cookies or other tracking technologies on your computer, mobile phone, or other
      device to collect information about you as discussed above. These third parties (e.g., ad networks and ad servers
      such as Google Analytics, DoubleClick and others) may also serve tailored ads to you as you use the Internet and
      Internet-connected applications, and access their own cookies or other tracking technologies on your computer,
      mobile phone, or other device you use to access the Services to assist in this activity.
    </p>
    <p>
      We neither have access to, nor does this Privacy Policy govern, the use of cookies or other tracking technologies
      that may be placed on your device you use to access the Services by such non-affiliated third parties. If you are
      interested in more information about tailored browser advertising and how you can generally control cookies from
      being put on your computer to deliver tailored advertising, you may visit the{' '}
      <a href="http://optout.networkadvertising.org/?c=1#!/">Network Advertising Initiative’s Consumer Opt-Out link</a>,
      the <a href="http://optout.aboutads.info/?c=2#!/">Digital Advertising Alliance’s Consumer Opt-Out link</a>, or{' '}
      <a href="http://www.youronlinechoices.eu/">Your Online Choices</a>
      {
        ' to opt-out of receiving tailored advertising from companies that participate in those programs. To opt out of Google Analytics for display advertising or customize Google display network ads, visit the '
      }
      <a href="https://adssettings.google.com/">Google Ads Settings page</a>. We do not control these opt-out links or
      whether any particular company chooses to participate in these opt-out programs. We are not responsible for any
      choices you make using these mechanisms or the continued availability or accuracy of these mechanisms.
    </p>
    <p>
      Please note that if you exercise the choices above, you will still see advertising when you use the Services, but
      it will not be tailored to you based on your online behavior over time.
    </p>
    <h2>3. Notice Concerning Do Not Track</h2>
    <p>
      Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. We are committed to
      providing you with meaningful choices about the information collected on our website for third party purposes, and
      that is why we provide the variety of opt-out mechanisms listed above. However, we do not currently recognize or
      respond to browser-initiated DNT signals. To learn more about Do Not Track, you can do so{' '}
      <a href="https://allaboutdnt.com/">here</a>.
    </p>
    <h2>HOW WE SHARE AND DISCLOSE YOUR INFORMATION</h2>
    <p>VRLU will share your information in the following ways:</p>
    <h3>Affiliates and Subsidiaries.</h3>
    <p>
      We may share information we collect within the VRLU family of companies to deliver products and services to you,
      ensure a consistent level of service, and enhance our products, services, and your customer experience.
    </p>
    <h3>Service Providers.</h3>
    <p>
      We provide access to or share your information with select third parties who perform services on our behalf. They
      have access to perform these services but are prohibited from using your information for other purposes. They
      provide a variety of services to us, including billing, sales, marketing, product content and features,
      advertising, analytics, research, customer service, data storage, security, fraud prevention, payment processing,
      and legal services.
    </p>
    <h3>Protection of VRLU and Others.</h3>
    <p>
      By using the Services, you acknowledge and agree that we may access, retain and disclose the information we
      collect and maintain about you if required to do so by law or in a good faith belief that such access, retention
      or disclosure is reasonably necessary to: (a) comply with legal process (e.g. a subpoena or court order); (b)
      enforce our Terms of Service, this Privacy Policy, or other contracts with you, including investigation of
      potential violations thereof; (c) respond to claims that any content violates the rights of third parties; (d)
      respond to your requests for customer service; and/or (e) protect the rights, property or personal safety of VRLU,
      its agents and affiliates, its users and/or the public. This includes exchanging information with other companies
      and organizations for fraud protection, and spam/malware prevention, and similar purposes.
    </p>
    <h3>Business Transfers.</h3>
    <p>
      As we continue to develop our business, we may buy, merge, or partner with other companies. In such transactions
      (including in contemplation of such transactions), user information may be among the transferred assets. If a
      portion or all of our assets are sold or transferred to a third-party, customer information (including your email
      address) would likely be one of the transferred business assets. If such transfer is subject to additional
      mandatory restrictions under applicable laws, we will comply with such restrictions.
    </p>
    <h3>Public Forums.</h3>
    <p>
      Certain features of our Services make it possible for you to share comments publicly with other users. Any
      information that you submit through such features is not confidential, and we may use it for any purpose
      (including in testimonials or other marketing materials). Any information you post openly in these ways will be
      available to the public at large and potentially accessible through third-party search engines. Accordingly,
      please take care when using these features.
    </p>
    <h3>Aggregate/Anonymous Information.</h3>
    <p>
      From time to time, we may share Aggregate/Anonymous Information about use of the Services, such as by publishing a
      report on usage trends. The sharing of such data is unrestricted.
    </p>
    <h2>RETENTION OF YOUR INFORMATION</h2>
    <p>
      We keep your information for no longer than necessary for the purposes for which it is processed. The length of
      time for which we retain information depends on the purposes for which we collected and use it and/or as required
      to comply with applicable laws.
    </p>
    <h2>HOW WE PROTECT YOUR INFORMATION</h2>
    <p>
      VRLU takes technical and organizational security measures to protect the information provided via the Services
      from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email
      transmission is ever fully secure or error free. Please keep this in mind when disclosing any information to VRLU
      via the Internet.
    </p>
    <h2>THIRD PARTY LINKS AND FEATURES</h2>
    <p>
      {
        'The Services contain links to third-party websites such as social media sites, and also contain third-party plug-ins (such as the Facebook “like” button and Twitter “follow” button). If you choose to use these sites or features, you may disclose your information not just to those third-parties, but also to their users and the public more generally depending on how their services function. We are not responsible for the content or practices of those websites or services. The collection, use, and disclosure of your information will be subject to the privacy policies of the third party websites or services, and not this Privacy Policy. We urge you to read the privacy and security policies of these third-parties.'
      }
    </p>
    <h2>CHILDREN’S PRIVACY</h2>
    <p>
      The Services are intended for general audiences and not for children under the age of 13. If we become aware that
      we have inadvertently collected “personal information” (as defined by the United States Children’s Online Privacy
      Protection Act) from children under the age of 13 without valid parental consent, we will take reasonable steps to
      delete it as soon as possible. We do not knowingly process data of EU residents under the age of 16 without
      parental consent. If we become aware that we have collected data from an EU resident under the age of 16 without
      parental consent, we will take reasonable steps to delete it as soon as possible. We also comply with other age
      restrictions and requirements in accordance with applicable local laws.
    </p>
    <h2>DATA SUBJECT RIGHTS AND CHOICES</h2>
    <p>
      Depending on your jurisdiction of residence, you may have certain rights with respect to your information as
      further described in this section.
    </p>
    <h2>1. Your Legal Rights</h2>
    <p>
      If you would like further information in relation to your legal rights under applicable law or would like to
      exercise any of them, please contact us using the information in the “Contact Information” section below at any
      time. Your local laws (e.g., in the EU) may permit you to request that we:
    </p>
    <ul>
      <li>provide access to and/or a copy of certain information we hold about you</li>
      <li>
        prevent the processing of your information for direct-marketing purposes (including any direct marketing
        processing based on profiling)
      </li>
      <li>update information which is out of date or incorrect</li>
      <li>delete certain information which we are holding about you</li>
      <li>restrict the way that we process and disclose certain of your information</li>
      <li>transfer your information to a third party provider of services</li>
      <li>revoke your consent for the processing of your information</li>
    </ul>
    <p>
      We will consider all requests and provide our response within the time period stated by applicable law. Please
      note, however, that certain information may be exempt from such requests in some circumstances, which may include
      if we need to keep processing your information for our legitimate interests or to comply with a legal obligation.
      We may request you provide us with information necessary to confirm your identity before responding to your
      request.
    </p>
    <h2>2. Marketing Communications and Sharing</h2>
    <p>
      You may instruct us not to use your contact information to contact you by email, postal mail, or phone regarding
      products, services, promotions and special events that might appeal to your interests by contacting us using the
      information below. In commercial email messages, you can also opt out by following the instructions located at the
      bottom of such emails. Please note that, regardless of your request, we may still use and share certain
      information as permitted by applicable law. For example, you may not opt out of certain operational emails, such
      as those reflecting our relationship or transactions with you, or important notifications regarding classes in
      which you are enrolled.
    </p>
    <h2>LEGAL BASES FOR USE OF YOUR INFORMATION</h2>
    <p>The legal bases for using your information as set out in this Privacy Policy are as follows:</p>
    <ul>
      <li>
        Where use of your information is necessary to perform our obligations under a contract with you (for example, to
        comply with: the terms of service of our websites which you accept by browsing the websites/registering; and/or
        our contract to provide our Services to you);
      </li>
      <li>
        Where use of your information is necessary for our legitimate interests or the legitimate interests of others
        (for example, to provide security for our website and applications; operate our business and our Services; make
        and receive payments; comply with legal requirements and defend our legal rights; prevent fraud and to know the
        customer to whom we are providing Services);
      </li>
      <li>Where we are required to process information in accordance with an EU Member State legal obligation; or</li>
      <li>Where we have your consent, in accordance with applicable law.</li>
    </ul>
    <h2>INTERNATIONAL TRANSFER AND PRIVACY SHIELD</h2>
    <p>
      The information discussed in this Policy is processed in the United States by VRLU, whose principal office in the
      United States.
    </p>
    <p>
      VRLU complies with the EU-US Privacy Shield Framework and the Swiss-US Privacy Shield Framework as set forth by
      the U.S. Department of Commerce regarding the collection, use, and retention of personal data from the European
      Union and Switzerland to the United States, respectively. VRLU has certified to the Department of Commerce that it
      adheres to the Privacy Shield Principles of Notice, Choice, Accountability for Onward Transfer, Security, Data
      Integrity and Purpose Limitation, Access and Recourse, Enforcement and Liability. If there is any conflict between
      the policies in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern.
      To learn more about the Privacy Shield program, and to view our certification page, please visit{' '}
      <a href="https://www.privacyshield.gov/">{'https://www.privacyshield.gov/'}</a>.
    </p>
    <p>
      In compliance with the EU-US Privacy Shield and Swiss-US Privacy Shield Principles, VRLU commits to resolve
      complaints about your privacy and our collection or use of your personal data. European Union or Swiss citizens
      with inquiries or complaints regarding this privacy policy should first contact VRLU at{' '}
      <a href="mailto:privacy@VRLU.com" target="_blank">
        privacy@VRLU.com
      </a>
      . VRLU has further committed to refer unresolved privacy complaints under the EU-US and Swiss-US Privacy Shield
      Principles to an independent dispute resolution mechanism. If you do not receive timely acknowledgment of your
      complaint, or if your complaint is not satisfactorily addressed by VRLU, please visit the JAMS EU Privacy Shield
      web site at{' '}
      <a href="https://www.jamsadr.com/eu-us-privacy-shield">{'https://www.jamsadr.com/eu-us-privacy-shield'}</a> for
      more information and to file a complaint. Please note that if your complaint is not resolved through these
      channels, under limited circumstances, a binding arbitration option may be available before a Privacy Shield
      Panel. VRLU is subject to the investigatory and enforcement powers of the Federal Trade Commission (FTC) with
      respect to its compliance with the provisions of the EU-US and Swiss-US Privacy Shield.
    </p>
    <p>
      If you are located in the European Union or other regions with laws governing data collection and use that may
      differ from U.S. law, please note that you are transferring information to a country and jurisdiction that does
      not have the same data protection laws as your jurisdiction. As described above, we also may subcontract the
      processing of your data to, or otherwise share your data with, other members within the VRLU group of companies,
      service providers, and business partners in countries other than your country of residence, including the United
      States, in accordance with applicable law. Such third parties may be engaged in, among other things, the provision
      of Services to you, the processing of transactions and/or the provision of support services. By providing us with
      your information, you acknowledge any such transfer, storage or use. VRLU will take reasonable and appropriate
      steps necessary to ensure that any third party who is acting as a “data processor” under EU and Swiss terminology
      is processing the personal data we entrust to them in a manner that is consistent with the EU-US and Swiss-US
      Privacy Shield Principles. VRLU is potentially liable in cases of onward transfer to third parties of data of EU
      and Swiss individuals received pursuant to the EU-US and Swiss-US Privacy Shield, respectively.
    </p>
    <p>
      If applicable, you may make a complaint to the data protection supervisory authority in the country where you
      reside. Alternatively you may seek a remedy through local courts if you believe your rights have been breached.
    </p>
    <h2>PRIVACY NOTICE FOR RESIDENTS OF CERTAIN U.S. STATES</h2>
    <p>Some U.S. state laws (e.g., California and Nevada) provide state residents with additional privacy rights.</p>
    <h2>CHANGES TO OUR PRIVACY POLICY</h2>
    <p>
      We reserve the right to amend this Privacy Policy at any time to reflect changes in the law, our data collection
      and use practices, the features of our Services, or advances in technology. We will make the revised Privacy
      Policy accessible through the Services, so you should review the Privacy Policy periodically. You can know if the
      Privacy Policy has changed since the last time you reviewed it by checking the "Date of Last Revision" included at
      the beginning of the document. If we make a material change to the Policy, you will be provided with appropriate
      notice in accordance with legal requirements. By continuing to use the Services, you are confirming that you have
      read and understood the latest version of this Privacy Policy.
    </p>
    <h2>CONTACT INFORMATION</h2>
    <p>
      Please feel free to contact us if you have any questions about VRLU Privacy Policy or the information practices of
      the Services.
    </p>
    <p>You may contact us as follows:</p>
    <p>
      <a href="mailto:info@vrlu.com" target="_blank">
        info@vrlu.com
      </a>
    </p>
  </div>
);

export default Privacy;

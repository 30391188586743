import React from 'react';
import { GlobalContext } from '../services/context';
import Karaoke from './Karaoke';

const KaraokeIndex = ({ isProfile }) => {
  const context = React.useContext(GlobalContext);
  const [karaokes, setKaraokes] = React.useState([]);
  
  const loadKaraokes = async () => {
    let k = await context.getKaraokes();
    if (!k) return
    if (isProfile) {
      k = k.filter( k => k.is_unlocked === true );
    } else {
      k = k.filter( k => k.category === 'singalong' );
    }
    setKaraokes(k);
  };

  React.useEffect(() => {
    loadKaraokes();
  }, []);
  
  function buildHeader() {
    if(isProfile && karaokes.length > 0) {
      return <div className="subtitle page-content">Karaoke</div>
    }
    return null
  }
  
  return (
    <>
    {buildHeader()}
    <div className="card-index episode-index">
      {karaokes
        .sort((a, b) => a.featured_sort_order - b.featured_sort_order)
        .map((k) => (
          <Karaoke karaoke={k} key={k.uuid} />
        ))}
    </div>
    </>
  );
};

export default KaraokeIndex;

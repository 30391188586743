 import axios from 'axios';

 // eslint-disable-next-line no-undef
const apiUrl = process.env.DOMAIN;
export const defaultAxiosConfig = {
  "X-Requested-With": "XMLHttpRequest",
  "Accept": "application/json",
  "Content-Type": "application/json",
  "Accept-Country": "us",
  "Accept-Language": "en",
}

axios.defaults.headers.common = defaultAxiosConfig;

/**
 * @param {{name | email: string, password: string}} user
 * @param {'vrlu' | 'gps'} provider
 */
export const login = (user = {}, provider = 'vrlu') => {
  return axios.post(`${apiUrl}/users/sign_in`, { user, provider });
};

export const logout = () => {
  return axios.delete(`${apiUrl}/users/sign_out`);
};

export const fetchUser = () => {
  return axios.get(`${apiUrl}/users/current`);
};

/**
 * @param {user: {name: string, email: string, password: string, password_confirmation: string}} user
 */
export const signUp = (user) => {
  return axios.post(`${apiUrl}/users`, user);
};

/**
 * @param {string} courseId
 */
export const getCourseById = (courseId) => {
  return axios.get(`${apiUrl}/courses/${courseId}`);
};

export const getAllCourses = () => {
  return axios.get(`${apiUrl}/courses`);
};

/**
 * @param {string} coachId
 */
export const getCoursesByCoachId = (coachId) => {
  return axios.get(`${apiUrl}/courses/${coachId}/courses`);
};

/**
 * @param {string} courseId
 */
export const getEpisodesByCourseId = (courseId) => {
  return axios.get(`${apiUrl}/courses/${courseId}/lessons`);
};

export const getAllEpisodes = () => {
  return axios.get(`${apiUrl}/lessons`);
};

/**
 * @param {string} lessonId
 */
export const getEpisodeById = (episodeId) => {
  return axios.get(`${apiUrl}/lessons/${episodeId}`);
};

/**
 * @param {string} storyId
 */
export const getStoryById = (storyId) => {
  return axios.get(`${apiUrl}/stories/${storyId}`);
};

export const getAllStories = () => {
  return axios.get(`${apiUrl}/stories`);
};

/**
 * @param {string} courseId
 */
export const purchaseCourse = (id) => {
  return axios.post(`${apiUrl}/courses/${id}/purchase`);
};

/**
 * @param {string} productId
 */
export const getProductById = (productId) => {
  return axios.get(`${apiUrl}/products/${productId}`);
};

/**
 * @param {string} productId
 */
export const purchaseProduct = (productId) => {
  return axios.post(`${apiUrl}/products/${productId}/purchase`);
};

export const getAllKaraoke = () => {
  return axios.get(`${apiUrl}/karaokes`);
};

/**
 * @param {string} karaokeId
 */
export const getKaraokeById = (karaokeId) => {
  return axios.get(`${apiUrl}/karaokes/${karaokeId}`);
};

export const getAllSeries = () => {
  return axios.get(`${apiUrl}/series`);
};

/**
 * @param {string} seriesId
 */
export const getSeriesById = (seriesId) => {
  return axios.get(`${apiUrl}/series/${seriesId}`);
};

/**
 * @param {string} seriesId
 */
export const getEpisodesBySeriesId = (seriesId) => {
  return axios.get(`${apiUrl}/series/${seriesId}/episodes`);
};

/**
 * @param {string} episodeId
 */
export const getSeriesEpisodeById = (episodeId) => {
  return axios.get(`${apiUrl}/episodes/${episodeId}`);
};

export const getAllMovies = () => {
  return axios.get(`${apiUrl}/movies`);
};

/**
 * @param {string} movieId
 */
export const getMovieById = (movieId) => {
  return axios.get(`${apiUrl}/movies/${movieId}`);
};

export const getUserReceipts = () => {
  return axios.get(`${apiUrl}/products/receipts`);
}

export const getUserOneTimeReceipts = () => {
  return axios.get(`${apiUrl}/courses/receipts`);
}

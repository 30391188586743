import React from 'react';
import { useParams } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { GlobalContext } from '../services/context';
import { purchaseCourse, getCourseById, getEpisodesByCourseId } from '../services/api';
import EpisodeCourse from '../components/Episode--Course';
import { BrowserLink } from '../enums';
import { calculatePricing } from '../utils/helper';

const CourseDetail = (props) => {
  const context = React.useContext(GlobalContext);
  
  const { id } = useParams();
  const stripe = useStripe();

  const [state, setState] = React.useState({
    course: undefined,
    episodes: undefined,
  });
  const [pricing, setPricing] = React.useState({
    strikePriceString: undefined,
    actualPriceString: undefined,
    percentOff: undefined,
  });
  const [ isRedirecting, setIsRedirecting ] = React.useState(false);

  React.useEffect(() => {
    if (state.course){
      setPricing(calculatePricing(state.course.prices, context.language));
    }
  }, [state.course, context.language]);

  React.useEffect(() => {
    const {
      history: { location },
    } = props;
    if (location.state && location.state.course && context.episodes.length) {
      const crs = props.history.location.state.course;
      const episodes = context.episodes.filter((episode) => episode.course_id === crs.uuid);
      setState({ ...state, course: crs, episodes });
    } else if (id) {
      getCourse();
    }
  }, [props.history, context.episodes, context.location]);

  const getCourse = () => {
    getCourseById(id)
      .then((res) => {
        getEpisodes(res.data.course);
      })
      .catch((err) => {
        console.log(err);
        // TODO: Handle error
      });
  };

  const getEpisodes = (course) => {
    getEpisodesByCourseId(id)
      .then((res) => {
        setState({ ...state, episodes: res.data.lessons, course });
      })
      .catch((err) => {
        console.log(err);
        // TODO: Handle error
      });
  };

  const sendToCheckout = () => {
    setIsRedirecting(true);
    if (!context.currentUser) {
      props.history.push({
        pathname: BrowserLink.login,
        state: { course: state.course, slide: true },
      });
    } else {
      purchaseCourse(state.course.uuid)
        .then((res) => {
          stripe.redirectToCheckout({ sessionId: res.data.id });
        })
        .catch((err) => {
          setIsRedirecting(false);
          // TODO: HANDLE ERROR;
          console.log(err);
        });
    }
  };

  if (state.course && state.episodes && state.episodes.length > 0) {
    const {
      episodes,
      course: { web_cover_url, coach, name, description, web_preview_url, is_unlocked },
    } = state;
    return (
      <div className="experience-detail">
        <img className="experience-cover" src={web_cover_url} />
        <div className="experience-cover-overlay" />
        <div className="experience-intro page-content">
          <div className="page-title">{coach.name}</div>
          <div className="section-title">{name}</div>
          {!is_unlocked && (
            <>
              {pricing.actualPriceString && (
                <div className="experience-price">
                  {pricing.strikePriceString && (
                    <div className="discount">
                      <div className="discount-percent">{pricing.percentOff}% off</div>
                      <div className="full-price">{pricing.strikePriceString}</div>
                    </div>
                  )}
                  <div className="discounted-price">{pricing.actualPriceString}</div>
                  <div className="subtitle">One time purchase</div>
                </div>
              )}
              <button className="get-button-large" disabled={isRedirecting} onClick={sendToCheckout}>
                Get Started
              </button>
            </>
          )}
        </div>
        <div className="page-content episode-specs specs">
          <div className="section-title">About this Course</div>
          <div className="subtitle">{description}</div>
          <div className="experience-spec">
            <div className="spec-title">{episodes.length} Classes</div>
            <div className="subtitle">Exclusive Lessons</div>
          </div>
          <div className="experience-spec">
            <div className="spec-title">All Levels</div>
            <div className="subtitle">All Ages</div>
          </div>
          <div className="experience-spec">
            <div className="spec-title">CC Available</div>
            <div className="subtitle">English, Korean, & Japanese</div>
          </div>
          <div className="experience-spec">
            <div className="spec-title">360 VR</div>
            <div className="subtitle">Immersive 3D</div>
          </div>
        </div>
        {web_preview_url && (
          <div>
            <div className="page-content">
              <div className="section-title">Preview this course</div>
            </div>
            <div className="trailer-container">
              <iframe src={web_preview_url} />
            </div>
          </div>
        )}
        <div className="page-content">
          <div className="section-title">Classes</div>
        </div>
        <div className="card-index episode-index">
          {episodes
            .sort((a, b) => a.number - b.number)
            .map((episode) => (
              <EpisodeCourse episode={episode} key={episode.uuid} />
            ))}
        </div>
      </div>
    );
  }
  return null;
};

export default CourseDetail;

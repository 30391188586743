import React from 'react';

const Terms = () => (
  <div>
    <h1>Terms of Service</h1>
    <p>Date of Last Revision: January 15, 2020</p>
    <h2>1. Welcome to VRLU!</h2>
    <h3>1.1 Introduction</h3>
    <p>
      VRLU (“VRLU,” “we,” “us,” “our”) provides its services (described below) to you through its website located at{' '}
      <a href="/">VRLU.com</a> (the “Site”) and through its mobile applications and related services (collectively, such
      services, including any new features and applications, and the Site, the “Service(s)”), subject to the following
      Terms of Service (the “Terms of Service”).{' '}
      <b>
        PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY GOVERN YOUR USE OF THE SITE AND SERVICES, PARTICULARLY
        SECTION 10 (BINDING ARBITRATION; CLASS ACTION WAIVER), WHICH AFFECTS YOUR RIGHTS IN THE EVENT OF A DISPUTE
        BETWEEN US.
      </b>
    </p>
    <h3>1.2 Modifications to Terms of Service</h3>
    <p>
      We reserve the right, at our sole discretion, to change or modify portions of these Terms of Service at any time.
      If we do this, depending on the nature of the change, we will post the changes on this page and indicate at the
      top of this page the date these terms were last revised and/or notify you, either through the Services{"'"} user
      interface, in an email notification or through other reasonable means and as required by applicable law. Any such
      changes will become effective no earlier than fourteen (14) days after they are posted, except that changes
      addressing new functions of the Services or changes made for legal reasons will be effective immediately. Your
      continued use of the Service after the date any such changes become effective constitutes your acceptance of the
      new Terms of Service. In addition, when using certain Services, you will be subject to any additional terms
      applicable to such Services that may be posted on the Service from time to time.
    </p>
    <h3>1.3 Privacy</h3>
    <p>
      At VRLU, we respect the privacy of our users. For details please see our <a href="/privacy">Privacy Policy</a>. By
      using the Service, you consent to our collection and use of personal data as outlined therein.
    </p>
    <h2>2. Access and Use of the Service</h2>
    <h3>2.1 Use Description</h3>
    <p>
      The VRLU service, and any content viewed through our service, is solely for your personal and non-commercial use.
      With your VRLU purchase we grant you a limited, non-exclusive, non-transferable, license to access the VRLU
      content and view your content through the service on a streaming-only basis for that purpose. Except for the
      foregoing limited license, no right, title or interest shall be transferred to you. You agree not to use the
      service for public performances. VRLU may revoke your license at any time in its sole discretion. Upon such
      revocation, you must promptly destroy all content downloaded or otherwise obtained through the service, as well as
      copies of such materials, whether made in accordance with these Terms of Service or otherwise.
    </p>
    <h3>2.2 Your Registration Obligations</h3>
    <p>
      You may be required to register with VRLU in order to access and use certain features of the Service. If you
      choose to register for the Service, you agree to provide and maintain true, accurate, current and complete
      information about yourself as prompted by the Service’s registration form. Registration data and certain other
      information about you are governed by our <a href="/privacy">Privacy Policy</a>. If you are under 13 years of age,
      you are not authorized to use the Service, with or without registering. In addition, if you are under 18 years
      old, you may use the Service, with or without registering, only with the approval of your parent or guardian.
    </p>
    <h3>2.3 Member Account, Password and Security</h3>
    <p>
      You may never use another's account, and you may not provide another person with the username and password to
      access your account. You are fully responsible for any and all activities that occur under your password or
      account, and it is your responsibility to ensure that your password remains confidential and secure. You agree to
      (a) immediately notify VRLU of any unauthorized use of your password or account or any other breach of security,
      and (b) ensure that you exit from your account at the end of each session when accessing the Service. VRLU will
      not be liable for any loss or damage arising from your failure to comply with this Section.
    </p>
    <h3>2.4 Modifications to Service</h3>
    <p>
      VRLU reserves the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof)
      with or without notice. You agree that VRLU will not be liable to you or to any third party for any modification,
      suspension or discontinuance of the Service. We have no obligation to retain any of Your Account or Submitted
      Content for any period of time beyond what may be required by applicable law.
    </p>
    <h3>2.5 General Practices Regarding Use and Storage</h3>
    <p>
      You acknowledge that VRLU may establish general practices and limits concerning use of the Service, including
      without limitation the maximum period of time that data or other content will be retained by the Service and the
      maximum storage space that will be allotted on VRLU’s servers on your behalf. You agree that VRLU has no
      responsibility or liability for the deletion or failure to store any data or other content maintained or uploaded
      by the Service. You acknowledge that VRLU reserves the right to terminate accounts that are inactive for an
      extended period of time. You further acknowledge that VRLU reserves the right to change these general practices
      and limits at any time, in its sole discretion, with or without notice.
    </p>
    <h3>2.6 Mobile Services</h3>
    <p>
      The Service may include certain services that are available via a mobile device, including (i) the ability to
      upload content to the Service via a mobile device, (ii) the ability to browse the Service and the Site from a
      mobile device and (iii) the ability to access certain features through an application downloaded and installed on
      a mobile device (collectively, the “Mobile Services”). To the extent you access the Service through a mobile
      device, your wireless service carrier’s standard charges, data rates and other fees may apply. In addition,
      downloading, installing, or using certain Mobile Services may be prohibited or restricted by your carrier, and not
      all Mobile Services may work with all carriers or devices. In using the Mobile Services, you may provide your
      telephone number. By providing your telephone number, you consent to receive calls and/or SMS, MMS, or text
      messages at that number. We may share your phone numbers with our affiliates or with our service providers (such
      as customer support, billing or collections companies, and text message service providers) who we have contracted
      with to assist us in pursuing our rights or providing our Services under these Terms of Service, our policies,
      applicable law, or any other agreement we may have with you. You agree these parties may also contact you using
      autodialed or prerecorded calls and text messages, as authorized by us to carry out the purposes we have
      identified above, and not for their own purposes. In the event you change or deactivate your mobile telephone
      number, you agree to promptly update your VRLU account information to ensure that your messages are not sent to
      the person that acquires your old number.
    </p>
    <h2>3. Conditions of Use</h2>
    <h3>3.1 User Conduct</h3>
    <p>
      You are solely responsible for all code, video, images, information, data, text, software, music, sound,
      photographs, graphics, messages or other materials (“content”) that you upload, post, publish or display
      (hereinafter, “upload”) or email or otherwise use via the Service. VRLU reserves the right to investigate and take
      appropriate legal action against anyone who, in VRLU's sole discretion, violates this provision, including without
      limitation, removing the offending content from the Service, suspending or terminating the account of such
      violators and reporting you to the law enforcement authorities. You agree to not use the Service to:
    </p>
    <ul>
      <li>
        email or otherwise upload any content that (i) infringes any intellectual property or other proprietary rights
        of any party; (ii) you do not have a right to upload under any law or under contractual or fiduciary
        relationships; (iii) contains software viruses or any other computer code, files or programs designed to
        interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications
        equipment; (iv) poses or creates a privacy or security risk to any person; (v) constitutes unsolicited or
        unauthorized advertising, promotional materials, commercial activities and/or sales, “junk mail,” “spam,” “chain
        letters,” “pyramid schemes,” “contests,” “sweepstakes,” or any other form of solicitation; (vi) is unlawful,
        harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene,
        pornographic, libelous, invasive of another’s privacy, hateful racially, ethnically or otherwise objectionable;
        or (vii) in the sole judgment of VRLU, is objectionable or which restricts or inhibits any other person from
        using or enjoying the Service, or which may expose VRLU or its users to any harm or liability of any type;
      </li>
      <li>
        interfere with or disrupt the Service or servers or networks connected to the Service, or disobey any
        requirements, procedures, policies or regulations of networks connected to the Service;
      </li>
      <li>
        violate any applicable local, state, national or international law, or any regulations having the force of law;
      </li>
      <li>
        impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or
        entity; solicit personal information from anyone under the age of 18;
      </li>
      <li>
        harvest or collect email addresses or other contact information of other users from the Service by electronic or
        other means for the purposes of sending unsolicited emails or other unsolicited communications;
      </li>
      <li>
        advertise or offer to sell or buy any goods or services for any business purpose that is not specifically
        authorized;
      </li>
      <li>
        further or promote any criminal activity or enterprise or provide instructional information about illegal
        activities; or
      </li>
      <li>
        obtain or attempt to access or otherwise obtain any materials or information through any means not intentionally
        made available or provided for through the Service.
      </li>
    </ul>
    <h3>3.2 Fees</h3>
    <p>
      To the extent the Service or any portion thereof is made available for any fee, you will be required to select a
      payment plan and provide VRLU information regarding your credit card or other payment instrument. You represent
      and warrant to VRLU that such information is true and that you are authorized to use the payment instrument. You
      will promptly update your account information with any changes (for example, a change in your billing address or
      credit card expiration date) that may occur. You agree to pay VRLU the amount that is specified in the payment
      plan in accordance with the terms of such plan and this Terms of Service. You hereby authorize VRLU to bill your
      payment instrument in accordance with the terms of the applicable payment plan until you terminate your account,
      and you further agree to pay any charges so incurred. If you dispute any charges you must let VRLU know within
      sixty (60) days after the date that VRLU charges you. We reserve the right to change VRLU's prices. Your continued
      use of the Service after the price change becomes effective constitutes your agreement to pay the changed amount.
      You shall be responsible for all taxes associated with the Services other than U.S. taxes based on VRLU's net
      income.
    </p>
    <h3>3.3 Recurring Subscriptions</h3>
    <p>
      If you select a Service with an auto renewal feature (“Recurring Subscription”), you authorize VRLU to maintain
      your account information and charge that account automatically upon the renewal of the Service you choose with no
      further action required by you. In the event that VRLU is unable to charge your account as authorized by you when
      you enrolled in a Recurring Subscription, VRLU, may, in its sole discretion: (i) bill you for your Service and
      suspend your access to the Service until payment is received, and/or (ii) seek to update your account information
      through third party sources (i.e., your bank or a payment processor) to continue charging your account as
      authorized by you.
    </p>
    <p>
      VRLU may change the price for Recurring Subscriptions from time to time and will communicate any price changes to
      you in advance and, if applicable, how to accept those changes. Price changes for Recurring Subscriptions will
      take effect at the start of the next subscription period following the date of the price change. As permitted by
      local law, you accept the new price by continuing to use your Recurring Subscription after the price change takes
      effect. If you do not agree with the price changes, you have the right to reject the change by cancelling your
      Recurring Subscription before the price change goes into effect. Please therefore make sure you read any such
      notification of price changes carefully.
    </p>
    <p>
      Payments are nonrefundable and there are no refunds or credits for partially used periods. You may cancel a
      Recurring Subscription at any time, but if you cancel your subscription before the end of the current subscription
      period, we will not refund any subscription fees already paid to us. Following any cancellation, however, you will
      continue to have access to the service through the end of your current subscription period. At any time, and for
      any reason, we may provide a refund, discount, or other consideration to some or all of our users ("credits"). The
      amount and form of such credits, and the decision to provide them, are at our sole and absolute discretion. The
      provision of credits in one instance does not entitle you to credits in the future for similar instances, nor does
      it obligate us to provide credits in the future, under any circumstance.
    </p>
    <p>
      If you purchase any Service through a mobile purchase or third-party marketplace (e.g., through the Apple App
      Store or Google Play Store), the refund policy applicable to that third-party marketplace will apply, unless
      otherwise explicitly stated by VRLU. Except as otherwise explicitly stated by VRLU, the third-party marketplace
      will be solely responsible for making refunds under its refund policy, and VRLU will have no refund obligations.
      VRLU disclaims any responsibility or liability related to any third-party marketplace's refund policy or the third
      party's compliance or noncompliance with such policy.
    </p>
    <p>
      If you subscribed via iTunes on your Apple mobile device, you can cancel by going to your Account Settings in the
      App Store. You can find Subscriptions in the Settings app on your device under iTunes & App Store, and then select
      your Apple ID.
    </p>
    <p>
      If you subscribed via the Google Play Store on your Android mobile device, you can cancel by opening the Google
      Play Store and selecting Menu Subscription. You can select the subscription you want to cancel or update and then
      follow the onscreen instructions.
    </p>
    <p>
      If you subscribed on our website, you can cancel by contacting Support at{' '}
      <a href="mailto:info@vrlu.com" target="_blank">
        info@vrlu.com
      </a>{' '}
      or by going to Settings in your account, selecting Cancel under the Subscription box and then following the
      onscreen instructions to continue and cancel subscription.
    </p>
    <h3>3.4 Special Notice for International Use; Export Controls</h3>
    <p>
      Software (defined below) available in connection with the Service and the transmission of applicable data, if any,
      is subject to United States export controls. No Software may be downloaded from the Service or otherwise exported
      or re-exported in violation of U.S. export laws.
    </p>
    <p>
      Downloading or using the Software is at your sole risk. Recognizing the global nature of the Internet, you agree
      to comply with all local rules and laws regarding your use of the Service, including as it concerns online conduct
      and acceptable content.
    </p>
    <h3>3.5 Commercial Use</h3>
    <p>
      Unless otherwise expressly authorized herein or by VRLU in writing, you agree not to display, distribute, license,
      perform, publish, reproduce, duplicate, copy, create derivative works from, modify, sell, resell, exploit,
      transfer or upload for any commercial purposes, any portion of the Service, use of the Service, or access to the
      Service.
    </p>
    <h2>4. Intellectual Property Rights</h2>
    <h3>4.1 Service Content, Software and Trademarks</h3>
    <p>
      You acknowledge and agree that the Service may contain content or features (“Service Content”) that are protected
      by copyright, patent, trademark, trade secret or other proprietary rights and laws. Except as expressly authorized
      by VRLU, you agree not to modify, copy, frame, scrape, rent, lease, loan, sell, distribute or create derivative
      works based on the Service or the Service Content, in whole or in part, except that the foregoing does not apply
      to your own User Content (as defined below) that you legally upload to the Service. In connection with your use of
      the Service you will not engage in or use any data mining, robots, scraping or similar data gathering or
      extraction methods. If you are blocked by VRLU from accessing the Service (including blocking your IP address),
      you agree not to implement any measures to circumvent such blocking (e.g., by masking your IP address or using a
      proxy IP address). Any use of the Service or the Service Content other than as specifically authorized herein is
      strictly prohibited. The technology and software underlying the Service or distributed in connection therewith are
      the property of VRLU, our affiliates and our partners (the “Software”). You agree not to copy, modify, create a
      derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell,
      assign, sublicense, or otherwise transfer any right in the Software. Any rights not expressly granted herein are
      reserved by VRLU.
    </p>
    <p>
      The VRLU name and logos are trademarks and service marks of VRLU (collectively the “VRLU Trademarks”). Other VRLU,
      product, and service names and logos used and displayed via the Service may be trademarks or service marks of
      their respective owners who may or may not endorse or be affiliated with or connected to VRLU. Nothing in this
      Terms of Service or the Service should be construed as granting, by implication, estoppel, or otherwise, any
      license or right to use any of VRLU Trademarks displayed on the Service, without our prior written permission in
      each instance. All goodwill generated from the use of VRLU Trademarks will inure to our exclusive benefit.
    </p>
    <h3>4.2 Third Party Material</h3>
    <p>
      Under no circumstances will VRLU be liable in any way for any content or materials of any third parties (including
      users), including, but not limited to, for any errors or omissions in any content, or for any loss or damage of
      any kind incurred as a result of the use of any such content. You acknowledge that VRLU does not pre-screen
      content, but that VRLU and its designees will have the right (but not the obligation) in their sole discretion to
      refuse or remove any content that is available via the Service. Without limiting the foregoing, VRLU and its
      designees will have the right to remove any content that violates these Terms of Service or is deemed by VRLU, in
      its sole discretion, to be otherwise objectionable. You agree that you must evaluate, and bear all risks
      associated with, the use of any content, including any reliance on the accuracy, completeness, or usefulness of
      such content.
    </p>
    <h3>4.3 User Content Transmitted</h3>
    <p>
      Through the Service: With respect to the content or other materials you upload through the Service or share with
      other users or recipients (collectively, “User Content”), you represent and warrant that you own all right, title
      and interest in and to such User Content, including, without limitation, all copyrights and rights of publicity
      contained therein, and that you have all required rights to post or transmit such content or other materials
      without violation of any third-party rights. By uploading any User Content you hereby grant and will grant VRLU,
      its affiliated companies and partners (including but not limited to VRLU instructors, practitioners and other
      third parties providing instructional information through the Services, collectively “partners”) a nonexclusive,
      worldwide, royalty free, fully paid up, transferable, sublicensable, perpetual, irrevocable license to copy,
      display, upload, adapt, perform, publish, distribute (through multiple tiers of distribution and partnerships),
      store, modify and otherwise use and fully exploit your User Content in any and all media, form, medium, technology
      or distribution methods now known or later developed and for any and all purposes (commercial or otherwise).
    </p>
    <p>
      You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information relevant
      to the Service (“Submissions”), provided by you to VRLU, its affiliated companies or partners are non-confidential
      and VRLU, its affiliated companies and partners will be entitled to the unrestricted use and dissemination of
      these Submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to you.
    </p>
    <p>
      You acknowledge and agree that VRLU may preserve content and may also disclose content if required to do so by law
      or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal
      process, applicable laws or government requests; (b) enforce these Terms of Service; (c) respond to claims that
      any content violates the rights of third parties; or (d) protect the rights, property, or personal safety of VRLU,
      its users and the public. You understand that the technical processing and transmission of the Service, including
      your content, may involve (a) transmissions over various networks; and (b) changes to conform and adapt to
      technical requirements of connecting networks or devices.
    </p>
    <h3>4.4 Copyright Complaints</h3>
    <p>
      VRLU respects the intellectual property of others, and we ask our users to do the same. If you believe that your
      work has been copied in a way that constitutes copyright infringement, or that your intellectual property rights
      have been otherwise violated, you should notify VRLU of your infringement claim in accordance with the procedure
      set forth below.
    </p>
    <p>
      To be effective, the notification must be in writing and contain the following information: an electronic or
      physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual
      property interest; a description of the copyrighted work or other intellectual property that you claim has been
      infringed; a description of where the material that you claim is infringing is located on the Service, with enough
      detail that we may find it on the Service; your address, telephone number, and email address; a statement by you
      that you have a good faith belief that the disputed use is not authorized by the copyright or intellectual
      property owner, its agent, or the law; a statement by you, made under penalty of perjury, that the above
      information in your Notice is accurate and that you are the copyright or intellectual property owner or authorized
      to act on the copyright or intellectual property owner’s behalf.
    </p>
    <h3>4.5 Counter-Notice</h3>
    <p>
      If you believe that your User Content that was removed (or to which access was disabled) is not infringing, or
      that you have the authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to
      upload and use the content in your User Content, you may send a written counter-notice containing the following
      information to the Copyright Agent: your physical or electronic signature; identification of the content that has
      been removed or to which access has been disabled and the location at which the content appeared before it was
      removed or disabled; a statement that you have a good faith belief that the content was removed or disabled as a
      result of mistake or a misidentification of the content; and your name, address, telephone number, and email
      address, a statement that you consent to the jurisdiction of the federal court located within Clark County,
      Nevada, USA and a statement that you will accept service of process from the person who provided notification of
      the alleged infringement.
    </p>
    <p>
      If a counter-notice is received by the Copyright Agent, VRLU will send a copy of the counter-notice to the
      original complaining party informing that person that it may replace the removed content or cease disabling it in
      10 business days. Unless the copyright owner files an action seeking a court order against the content provider,
      member or user, the removed content may be replaced, or access to it restored, in 10 to 14 business days or more
      after receipt of the counter-notice, at our sole discretion.
    </p>
    <h3>4.6 Repeat Infringer Policy</h3>
    <p>
      In accordance with the DMCA and other applicable law, VRLU has adopted a policy of terminating, in appropriate
      circumstances and at VRLU's sole discretion, users who are deemed to be repeat infringers. VRLU may also at its
      sole discretion limit access to the Service and/or terminate the memberships of any users who infringe any
      intellectual property rights of others, whether or not there is any repeat infringement.
    </p>
    <h2>5. Third Party Websites</h2>
    <p>
      The Service may provide, or third parties may provide, links or other access to other sites and resources on the
      Internet. VRLU has no control over such sites and resources and VRLU is not responsible for and does not endorse
      such sites and resources. You further acknowledge and agree that VRLU will not be responsible or liable, directly
      or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance
      on any content, events, goods or services available on or through any such site or resource. Any dealings you have
      with third parties found while using the Service are between you and the third party, and you agree that VRLU is
      not liable for any loss or claim that you may have against any such third party.
    </p>
    <h2>6. Social Networking Services</h2>
    <p>
      You may enable or log in to the Service via various online third-party services, such as social media and social
      networking services like Facebook or Twitter (“Social Networking Services”). By logging in or directly integrating
      these Social Networking Services into the Service, we make your online experiences richer and more personalized.
      To take advantage of this feature and capabilities, we may ask you to authenticate, register for or log into
      Social Networking Services on the websites of their respective providers. As part of such integration, the Social
      Networking Services will provide us with access to certain information that you have provided to such Social
      Networking Services, and we will use, store and disclose such information in accordance with our{' '}
      <a href="/privacy">Privacy Policy</a>. For more information about the implications of activating these Social
      Networking Services and VRLU's use, storage and disclosure of information related to you and your use of such
      services within VRLU (including your friend lists and the like), please see our{' '}
      <a href="/privacy">Privacy Policy</a>.
    </p>
    <p>
      However, please remember that the manner in which Social Networking Services use, store and disclose your
      information is governed solely by the policies of such third parties, and VRLU shall have no liability or
      responsibility for the privacy practices or other actions of any third party site or service that may be enabled
      within the Service.
    </p>
    <p>
      In addition, VRLU is not responsible for the accuracy, availability or reliability of any information, content,
      goods, data, opinions, advice or statements made available in connection with Social Networking Services. As such,
      VRLU is not liable for any damage or loss caused or alleged to be caused by or in connection with use of or
      reliance on any such Social Networking Services. VRLU enables these features merely as a convenience and the
      integration or inclusion of such features does not imply an endorsement or recommendation.
    </p>
    <h2>7. Indemnity and Release</h2>
    <p>
      To the fullest extent permitted by law, you agree to release, indemnify and hold VRLU and its affiliates and their
      officers, employees, directors and agent harmless from any from any and all losses, damages, expenses, including
      reasonable attorneys’ fees, rights, claims, actions of any kind and injury (including death) arising out of or
      relating to your use of the Service, any User Content, your connection to the Service, your violation of these
      Terms of Service or your violation of any rights of another. If you are a California resident, you waive
      California Civil Code Section 1542, which says: “A general release does not extend to claims which the creditor
      does not know or suspect to exist in his favor at the time of executing the release, which if known by him must
      have materially affected his settlement with the debtor.” If you are a resident of another jurisdiction, you waive
      any comparable statute or doctrine.
    </p>
    <h2>8. Disclaimer of Warranties</h2>
    <p>
      YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
      EXCEPT AS OTHERWISE EXPRESSLY PROVIDED HEREIN, VRLU EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
      EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
      FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
    </p>
    <p>
      VRLU MAKES NO WARRANTY THAT (I) THE SERVICE WILL MEET YOUR REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED,
      TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE
      OR RELIABLE, OR (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED
      BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.
    </p>
    <h2>9. Limitation of Liability</h2>
    <p>
      YOU EXPRESSLY UNDERSTAND AND AGREE THAT VRLU WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
      CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
      GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF VRLU HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
      WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM: (I) THE USE OR THE
      INABILITY TO USE THE SERVICE; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY
      GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO
      THROUGH OR FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV)
      STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE SERVICE. IN NO
      EVENT WILL VRLU’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE
      PAID VRLU IN THE LAST SIX (6) MONTHS, OR, IF GREATER, ONE HUNDRED DOLLARS ($100).
    </p>
    <p>
      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY
      FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY
      TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND
      EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
    </p>
    <h2>10. Binding Arbitration; Class Action Waiver</h2>
    <p>
      You and VRLU agree that these Terms of Service affect interstate commerce and that the U.S. Federal Arbitration
      Act governs the interpretation and enforcement of these arbitration provisions. This Section 10 is intended to be
      interpreted broadly and governs any and all disputes between us including but not limited to claims arising out of
      or relating to any aspect of the relationship between us or the Terms of Service or the Service, whether based in
      contract, tort, statute, fraud, misrepresentation or any other legal theory. The only disputes excluded from this
      broad prohibition are the litigation of certain intellectual property and small court claims, as provided below.
    </p>
    <p>
      If you have any dispute with us, you agree that before taking any formal action, you will contact us at{' '}
      <a href="mailto:info@vrlu.com" target="_blank">
        info@vrlu.com
      </a>
      , and provide a brief, written description of the dispute and your contact information (including your username,
      if your dispute relates to an account). Except for intellectual property and small claims court claims, the
      parties agree to use their best efforts to settle any dispute, claim, question, or disagreement directly through
      consultation and good faith negotiations shall be a condition to either party initiating a lawsuit or arbitration.
    </p>
    <p>
      All disputes, claims, or controversies arising out of or relating to the Terms of Service or the Service that are
      not resolved by the procedures identified above shall be resolved by individual (not group) binding arbitration to
      be conducted before JAMS in accordance with the JAMS Streamlined Arbitration Procedure Rules for claims that do
      not exceed $250,000 and the JAMS Comprehensive Arbitration Rules and Procedures for claims exceeding $250,000 in
      effect at the time the arbitration is initiated, excluding any rules or procedures governing or permitting class
      actions. If you are a resident of the United States, arbitration may take place in the county where you reside at
      the time of filing, unless you and we both agree to another location or telephonic arbitration. For residents
      outside the United States, arbitration will be held in Clark County, NV, and you and VRLU agree to submit to the
      personal jurisdiction of any federal or state court in Clark County, Nevada in order to compel arbitration, stay
      proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by the
      arbitrator.
    </p>
    <p>
      The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve
      all disputes arising out of or relating to the interpretation, applicability, enforceability, or formation of
      these Terms of Service, including but not limited to any claim that all or any part of these Terms of Services are
      void or voidable, or whether a claim is subject to arbitration. The arbitrator shall be empowered to grant
      whatever relief would be available in a court under law or in equity. The arbitrator's award shall be written and
      shall be binding on the parties and may be entered as a judgment in any court of competent jurisdiction.
    </p>
    <p>
      VRLU shall bear the cost of any arbitration filing fees and arbitration fees for claims of up to $75,000, unless
      the arbitrator finds the arbitration to be frivolous. You are responsible for all other additional costs that you
      may incur in the arbitration including but not limited to attorneys’ fees and expert witness costs unless VRLU is
      otherwise specifically required to pay such fees under applicable law. If your claim is solely for monetary relief
      of $10,000 or less, and does not include a request for any type of equitable remedy, you may choose whether the
      arbitration will be conducted solely based on documents submitted to the arbitrator, through a telephonic hearing,
      or by an in-person hearing.
    </p>
    <p>
      You and we agree that the arbitration shall be conducted in the party’s respective individual capacities only and
      not as a class action or other representative action, and the parties expressly waive their right to file a class
      action or seek relief on a class basis. YOU AND VRLU AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN
      YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
      PROCEEDING. If any court or arbitrator determines that the class action waiver set forth in this paragraph is void
      or unenforceable for any reason or that an arbitration can proceed on a class basis, then the arbitration
      provisions set forth above shall be deemed null and void in their entirety and the parties shall be deemed to have
      not agreed to arbitrate disputes.
    </p>
    <p>
      Notwithstanding the parties' decision to resolve all disputes through arbitration, you or we may bring enforcement
      actions, validity determinations or claims arising from or relating to theft, piracy or unauthorized use of
      intellectual property in state or federal court with jurisdiction or in the U.S. Patent and Trademark Office to
      protect intellectual property rights ("intellectual property rights" means patents, copyrights, moral rights,
      trademarks, and trade secrets, but not privacy or publicity rights). You or we may also seek relief in a small
      claims court for disputes or claims within the scope of that court's jurisdiction to the extent such claims do not
      seek equitable relief.
    </p>
    <p>
      You have the right to opt out and not be bound by the arbitration and class action waiver provisions set forth
      above by sending written notice of your decision to opt out to VRLU at the address identified in Section 14 below.
      The notice must be sent within thirty (30) days of (a) the “Date of Last Revision” date of these Terms of Service
      as set forth above; or (b) your first date that you used the Services that contained any versions of the Terms of
      Service that included this version of the mandatory arbitration and class action waiver, whichever is later.
      Otherwise you shall be bound to arbitrate disputes in accordance with the terms of these paragraphs. If you opt
      out of these arbitration provisions, VRLU also will not be bound by them. If VRLU changes this ‘Arbitration’
      section after the date you first accepted these Terms of Service (or accepted any subsequent changes to these
      Terms of Service), you may reject any such change by sending us written notice within 30 days of the date such
      change became effective, as indicated in the “Date of Last Revision” date above or in the date of VRLU' email to
      you notifying you of such change. By rejecting any change, you are agreeing that you will arbitrate any dispute
      between you and VRLU in accordance with the provisions of this section as of the date you first accepted these
      Terms of Service (or accepted any subsequent changes to these Terms of Service).
    </p>
    <p>
      YOU UNDERSTAND AND AGREE THAT THE ABOVE DISPUTE PROCEDURES SHALL BE YOUR SOLE REMEDY IN THE EVENT OF DISPUTE
      BETWEEN YOU AND VRLU REGARDING ANY ASPECT OF THE SERVICE (INCLUDING THE ENROLMENT PROCESS) AND THAT YOU ARE
      WAIVING YOUR RIGHT TO LEAD OR PARTICIPATE IN A LAWSUIT INVOLVING OTHER PERSONS, SUCH AS A CLASS ACTION.
    </p>
    <h2>11. Termination</h2>
    <p>
      You agree that VRLU, in its sole discretion, may suspend or terminate your account (or any part thereof) or use of
      the Service and remove and discard any content within the Service, for any reason, including, without limitation,
      for lack of use or if VRLU believes that you have violated or acted inconsistently with the letter or spirit of
      these Terms of Service. Any suspected fraudulent, abusive or illegal activity that may be grounds for termination
      of your use of Service, may be referred to appropriate law enforcement authorities. VRLU may also in its sole
      discretion and at any time discontinue providing the Service, or any part thereof, with or without notice. You
      agree that any termination of your access to the Service under any provision of this Terms of Service may be
      effected without prior notice, and acknowledge and agree that VRLU may immediately deactivate or delete your
      account and all related information and files in your account and/or bar any further access to such files or the
      Service. Further, you agree that VRLU will not be liable to you or any third party for any termination of your
      access to the Service.
    </p>
    <h2>12. Disputes Between Users</h2>
    <p>
      You agree that you are solely responsible for your interactions with any other user in connection with the Service
      and VRLU will have no liability or responsibility with respect thereto. VRLU reserves the right, but has no
      obligation, to become involved in any way with disputes between you and any other user of the Service.
    </p>
    <h2>13. General</h2>
    <p>
      These Terms of Service constitute the entire agreement between you and VRLU and govern your use of the Service,
      superseding any prior agreements between you and VRLU with respect to the Service. You also may be subject to
      additional terms and conditions that may apply when you use affiliate or third-party services, third party content
      or third-party software. These Terms of Service will be governed by the laws of the State of California without
      regard to its conflict of law provisions. With respect to any disputes or claims not subject to arbitration, as
      set forth in Section 10 above, you and VRLU agree to submit to the personal and exclusive jurisdiction of the
      state and federal courts located within San Francisco County, California. The failure of VRLU to exercise or
      enforce any right or provision of these Terms of Service will not constitute a waiver of such right or provision.
      If any provision of these Terms of Service is found by a court of competent jurisdiction to be invalid, the
      parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected
      in the provision, and the other provisions of these Terms of Service remain in full force and effect. You agree
      that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to
      use of the Service or these Terms of Service must be filed within one (1) year after such claim or cause of action
      arose or be forever barred. A printed version of this agreement and of any notice given in electronic form will be
      admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent
      and subject to the same conditions as other business documents and records originally generated and maintained in
      printed form. You may not assign this Terms of Service without the prior written consent of VRLU, but VRLU may
      assign or transfer this Terms of Service, in whole or in part, without restriction. The section titles in these
      Terms of Service are for convenience only and have no legal or contractual effect. Notices to you may be made via
      either email or regular mail. Under no circumstances shall VRLU be held liable for any delay or failure in
      performance resulting directly or indirectly from an event beyond its reasonable control. The Service may also
      provide notices to you of changes to these Terms of Service or other matters by displaying notices or links to
      notices generally on the Service.
    </p>
    <h2>14. Questions? Concerns? Suggestions?</h2>
    <p>
      Please contact us at{' '}
      <a href="mailto:info@vrlu.com" target="_blank">
        info@vrlu.com
      </a>{' '}
      to report any violations of these Terms of Service or to pose any questions regarding this Terms of Service or the
      Service.
    </p>
  </div>
);

export default Terms;

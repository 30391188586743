import React from 'react';
import { GlobalContext } from '../services/context';
import { SupportedLanguages, Flags, LanguageByInitials } from '../enums/Localizations'

const LanguageSelect = () => {
  const context = React.useContext(GlobalContext);
  const [showLanguageSelect, setShowLanguageSelect] = React.useState(false);

  function handleLangSelect(lang) {
    context.updateLanguage(lang);
    setShowLanguageSelect(false)
  };

  return (
    <div className="country-select-container">
      <div className="row">
        <i className={Flags[context.language]} onClick={() => setShowLanguageSelect(true)} />
        {LanguageByInitials[context.language]}
      </div>
      {showLanguageSelect && (
        <div className="country-container">
          {SupportedLanguages.filter(lang => lang !== context.language).map(lang => {
            return (
              <span key={lang} className="row">
                <i className={Flags[lang]} onClick={() => handleLangSelect(lang)} />
                {LanguageByInitials[lang]}
              </span>
            )
          })}
        </div>
      )}
    </div>
  );
};

export default LanguageSelect;

import React from 'react';
import { Link } from 'react-router-dom';
import { BrowserLink } from '../enums';
import { login } from '../services';
import { GlobalContext } from '../services/context';

class GPS extends React.Component {
  state = {
    username: '',
    password: '',
    error: false,
    close: false,
  };

  static contextType = GlobalContext;

  componentDidMount() {
    document.body.style.position = 'fixed';
  }

  update = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      error: false,
    });
  };

  close = () => {
    const {
      history: { location },
    } = this.props;
    console.log(this.props);
    let redirectUrl = '/';
    const experience = location.state && location.state.experience || undefined;
    if (experience) {
      redirectUrl = `${BrowserLink.courses}/${experience.uuid}`;
    }
    document.body.style.position = '';
    this.props.history.push({
      pathname: redirectUrl,
      state: { experience: experience },
    })
  };

  componentWillUnmount() {
    document.body.style.position = '';
  }

  submit = (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    const user = { username, password };
    login(user, 'gps')
      .then((res) => {
        const { user } = res.data;
        this.context.login(user);
        this.close();
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
        this.setState({ error: true });
      });
  };

  render() {
    const { username, password, error, close } = this.state;
    const slide = this.props.location.state && this.props.location.state.slide;
    return (
      <div className={'form-screen' + (close ? ' close' : '')}>
        <div className="form">
          <i className="form-logo in-form" onClick={this.close} />
          <div className="form-title">GPS LOGIN</div>
          <div className="form-subtitle">
            You’re just moments away from immersing yourself in all out virtual reality.
          </div>
          <form onSubmit={this.submit}>
            <label className="form-label">
              GPS ID
              <input name="username" value={username} onChange={this.update} />
            </label>
            <label className="form-label">
              Password
              <input type="password" name="password" value={password} onChange={this.update} />
            </label>
            <div className="server-error">{error && <span>Invalid Username or Password</span>}</div>
            <button type="submit" className="button gps-button">
              <i className="gps-logo" />
              <span>Log in with GPS</span>
            </button>
          </form>
          <div className="or-container">
            <div className="or">Or</div>
          </div>
          <Link to={BrowserLink.login} className="button submit-button">
            <span>Log in with VRLU</span>
          </Link>
        </div>
        <div className="form-background gps-background">
          <i className="form-logo" onClick={this.close} />
          <div className="form-copy-title">Welcome GPS member.</div>
          <div className="form-copy">We hope you are as excited as we are to be here. See you in virtual reality.</div>
        </div>
      </div>
    );
  }
}

export default GPS;

import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { GlobalContext } from '../services/context';


const ProtectedRoute = ({ component: Component, ...rest }) => {
  const context = React.useContext(GlobalContext);
  const { currentUser } = context;
  return (
    <Route
      {...rest}
      render={props => (
        currentUser
          ? (<Component {...props} />)
          : (
            <Redirect
              to={{
                pathname: "/login",
                state: { ...props.location.state, from: props.location.pathname }
              }}
            />)
      )}
    />
  );
};

export default ProtectedRoute;

export const BrowserLink = {
  privacy: '/privacy',
  terms: '/terms',
  profile: '/profile',
  settings: '/settings',
  courses: '/masters-academy/courses',
  series: '/coni-tv/series',
  orders: '/orders',
  gps: '/gps',
  signUp: '/sign-up',
  login: '/login',
  success: '/success',
  mastersAcademy: '/masters-academy',
  kpop: '/kpop',
  coniTV: '/coni-tv'
};

import React from 'react';
import { GlobalContext } from '../services/context';
import Course from './Course.jsx';

const CoursesIndex = ({ isProfile }) => {
  const context = React.useContext(GlobalContext);
  const [courses, setCourses] = React.useState([]);
  
  const loadCourses = async () => {
    let c = await context.getCourses();
    if (!c) return;
    if (isProfile) {
      c = c.filter( c => c.is_unlocked === true );
    }
    setCourses(c);
  }
  
  React.useEffect(() => {
    loadCourses();
  }, []);

  function buildHeader() {
    if(isProfile && courses.length > 0) {
      return <div className="subtitle page-content">Masters</div>
    }
    return null
  }

  return (
    <>
    {buildHeader()}
    <div className="card-index">
      {courses
        .sort((a, b) => a.featured_sort_order - b.featured_sort_order)
        .map((c) => (
          <Course course={c} key={c.uuid} />
        ))}
    </div>
    </>
  );
};

export default CoursesIndex;

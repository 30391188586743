import React, { useMemo } from 'react';
import { useLocation, useParams } from "react-router-dom";
import queryString from 'query-string';



/**
 * @param {prices} price[] 
 * @param {string} language
 */
export const calculatePricing = (prices, language) => {
  const actualPriceObj = prices.find( p => p.price_type === 'actual');
  const strikePriceObj = prices.find( p => p.price_type === 'strike');
  let actualPriceString, actualPriceAmount, strikePriceString, strikePriceAmount, percentOff;
  if (actualPriceObj) {
    actualPriceAmount = 
      actualPriceObj.currency_code.toLowerCase() === 'usd' ? 
        actualPriceObj.amount / 100 
          : actualPriceObj.amount;
    actualPriceString = new Intl.NumberFormat(language, { 
      style: 'currency', 
      currency: actualPriceObj.currency_code,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }).format(actualPriceAmount);
  }
  if (strikePriceObj) {
    strikePriceAmount = 
      strikePriceObj.currency_code.toLowerCase() === 'usd' ? 
        strikePriceObj.amount / 100 
          : strikePriceObj.amount;
    strikePriceString = new Intl.NumberFormat(language, { 
      style: 'currency', 
      currency: strikePriceObj.currency_code,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }).format(strikePriceAmount);
  }
  if (actualPriceString && strikePriceString) {
    const percentPaid = (actualPriceAmount / strikePriceAmount) * 100;
    percentOff = Math.round(100 - percentPaid)
  }
  return {
    actualPriceString,
    strikePriceString,
    percentOff
  }
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
  const location = useLocation();
  const params = useParams();

  return useMemo(() => {
    return {
      ...queryString.parse(location.search),
      ...params
    };
  }, [location]);
};

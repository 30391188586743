import React from 'react';

const Karaoke = ( props ) => {
  const { karaoke } = props;

  return (
    <div role="a" className="episode card">
      <img src={karaoke.landscape_thumbnail_url} />
      <div className="card-overlay" />
      <div className="card-details">
        <div className="card-title">{karaoke.title}</div>
        <div className="card-description">{karaoke.artist}</div>
        {/* {!karaoke.is_unlocked && (
          <button className="get-button-small" disabled={isRedirecting} onClick={sendToCheckout}>
            Get
          </button>
        )} */}
      </div>
    </div>
  );
};
export default Karaoke;

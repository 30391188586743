import React from 'react';

const EpisodeCourse = ({ episode }) => (
  <div className="episode card">
    <img src={episode.thumbnail_url} />
    <div className="card-overlay" />
    <div className="card-details">
      <div className="card-title">Class {episode.number}</div>
      <div className="card-description">{episode.name}</div>
    </div>
  </div>
);

export default EpisodeCourse;

import React from 'react'
import { useStripe } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { getProductById, GlobalContext, purchaseProduct } from '../services';
import KaraokeIndex from '../components/KaraokeIndex';
import DiveIndex from '../components/DiveIndex';
import { BrowserLink } from '../enums';
import { calculatePricing, useQuery } from '../utils/helper';

const Kpop = () => {
  const context = React.useContext(GlobalContext);
  const query = useQuery();
  const [isRedirecting, setIsRedirecting] = React.useState(false);
  const [hasActiveSub, setHasActiveSub] = React.useState(true);
  const [pricing, setPricing] = React.useState({
    strikePriceString: undefined,
    actualPriceString: undefined,
    percentOff: undefined,
  });
  const stripe = useStripe();
  const history = useHistory();
  
  React.useEffect(() => {
    if (query.checkout === "1") {
      sendToCheckout();
    }
  }, [query.checkout]);
  
  // eslint-disable-next-line no-undef
  const PROD_ID = process.env['KARAOKE_SUBSCRIPTION_PRODUCT_ID'];

  const loadSubscriptionStatus = async () => {
    await context.getUserSubscriptions();
    const activeStatus = await context.subscriptionActiveStatus(PROD_ID);
    setHasActiveSub(activeStatus);
  };

  React.useEffect(() => {
    if (context.currentUser) {
      loadSubscriptionStatus();
    }
  }, []);

  React.useEffect(() => {
    if (context.language) {
        
      getProductById(PROD_ID)
        .then((res) => {
          const { product } = res.data;
          setPricing(calculatePricing(product.prices, context.language));
        })
        .catch((err) => {
          // TODO: Handle error
          console.log(err);
        });
    }
  }, [context.language]);

  function sendToCheckout(event) {
    event?.stopPropagation();
    setIsRedirecting(true);
    if (!context.currentUser) {
      history.push({
        pathname: BrowserLink.login,
        state: { redirectTo: BrowserLink.kpop, slide: true, checkout: 1 },
      });
    } else {
      // eslint-disable-next-line no-undef
      purchaseProduct(process.env['KARAOKE_SUBSCRIPTION_PRODUCT_ID'])
        .then((res) => {
          stripe.redirectToCheckout({ sessionId: res.data.id });
        })
        .catch((err) => {
          setIsRedirecting(false);
          // TODO: HANDLE ERROR;
          console.log(err);
        });
    }
  };
  
  return (
    <div className="kpop">
      <div className="kpop-background kpop-bg">
        <div className="vertical-gradient" />
        <div className="horizontal-gradient" />
      </div>
        
      <div className="welcome">
        <div className="page-content">
          {/* <div className="vroom-logo"></div> */}
          <div className="page-title">Experience K-POP stages and sing your heart out in VR karoake.</div>
          {/* {context.language === Language.Korean && (
            <div className="subtitle">
              각 분야 최고의 마스터에게 삶의 철학과 지혜를 배우고, 분야별 전문 지식과 노하우를 전수받을 수 있는 실감
              체험형 자기계발 콘텐츠
            </div>
          )} */}
          {(!context.currentUser || !hasActiveSub) && (
            <div className="flex items-center get-started">
              <button className="btn btn-gradient" disabled={isRedirecting} onClick={sendToCheckout}>GET STARTED</button>
              {pricing.actualPriceString && (
                <>
                <span className="light-text billing-text">K-POP VRoom is</span>
                {pricing.strikePriceString && <span className="light-text billing-text strikethrough">{pricing.strikePriceString}</span>}
                <span className="light-text billing-text">{`${pricing.actualPriceString} / month`}</span>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {/* <div className="page-content">
        <div className="section-title">Watch The K-POP VRoom Trailer</div>
      </div>
      <div className="trailer">
        <div className="trailer-singer"></div>
      </div> */}
      <div className="tabs-container mt-4">
        <Tabs>
          <TabList>
            <Tab
              className="kpop-tab-label"
              selectedClassName="kpop-tab-label--selected"
            >Karaoke</Tab>
            <Tab
              className="kpop-tab-label"
              selectedClassName="kpop-tab-label--selected"
            >Dive</Tab>
          </TabList>
      
          <TabPanel>
            <KaraokeIndex />
          </TabPanel>
          <TabPanel>
            <DiveIndex />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default Kpop;

import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { GlobalContext } from '../services/context';
import { purchaseCourse, getEpisodesBySeriesId, getSeriesById } from '../services/api';
import EpisodeSeries from '../components/Episode--Series';
import { BrowserLink } from '../enums';

const SeriesDetail = (props) => {
  const { id } = useParams();
  const [state, setState] = React.useState({
    series: undefined,
    episodes: undefined,
    strikePriceString: undefined,
    actualPriceString: undefined,
    percentOff: undefined,
  });
  const [ isRedirecting, setIsRedirecting ] = React.useState(false);
  const [headers, setHeaders] = React.useState({});
  const stripe = useStripe();
  const context = React.useContext(GlobalContext);

  React.useEffect(() => {
    if (state.series){
      // calculatePricing();
    }
  }, [state.series, context.language]);

  React.useEffect(() => {
    
    const {
      history: { location },
    } = props;
    if (location.state && location.state.series) {
      const s = props.history.location.state.series;
      getSeriesById(s.uuid)
        .then((res) => {
          setHeaders(res.headers)
        })

      getEpisodesBySeriesId(s.uuid)
        .then((res) => {
          const { episodes } = res.data;
          setState({ ...state, series: s, episodes });
          context.updateSeriesEpisodes(episodes);
        })
        .catch((err) => {
          // TODO: Handle error
          console.log(err);
        });
    } else if (id) {
      // getExperience();
    }
  }, [props.history, context.series, context.location]);

  const sendToCheckout = () => {
    setIsRedirecting(true);
    if (!context.currentUser) {
      props.history.push({
        pathname: BrowserLink.login,
        state: { redirectTo: `${BrowserLink.series}/${state.series.uuid}`, slide: true },
      });
    } else {
      purchaseCourse(state.experience.uuid)
        .then((res) => {
          stripe.
          stripe.redirectToCheckout({ sessionId: res.data.id });
        })
        .catch((err) => {
          setIsRedirecting(false);
          // TODO: HANDLE ERROR;
          console.log(err);
        });
    }
  };

  if (state.series && state.episodes && state.episodes.length > 0) {
    const {
      episodes,
      series: { landscape_thumbnail_url, trailer_stream_url, title, description, is_unlocked },
    } = state;
    return (
      <div className="experience-detail">
        <img className="experience-cover" src={landscape_thumbnail_url} />
        <div className="experience-cover-overlay" />
        <div className="experience-intro page-content">
          {/* <div className="section-title">{coach.name}</div> */}
          <div className="page-title">{title}</div>
          {/* {!is_unlocked && (
            <>
              {state.actualPriceString && (
                <div className="experience-price">
                  {state.strikePriceString && (
                    <div className="discount">
                      <div className="discount-percent">{state.percentOff}% off</div>
                      <div className="full-price">{state.strikePriceString}</div>
                    </div>
                  )}
                  <div className="discounted-price">{state.actualPriceString}</div>
                  <div className="subtitle">One time purchase</div>
                </div>
              )}
              <button className="get-button-large" disabled={isRedirecting} onClick={sendToCheckout}>
                Get Started
              </button>
            </>
          )} */}
        </div>
        <div className="page-content episode-specs specs">
          <div className="section-title">About this Series</div>
          <div className="subtitle">{description}</div>
          <div className="experience-spec">
            <div className="spec-title">{episodes.length} Episodes</div>
            <div className="subtitle">Exclusive Lessons</div>
          </div>
          <div className="experience-spec">
            <div className="spec-title">All Levels</div>
            <div className="subtitle">All Ages</div>
          </div>
          <div className="experience-spec">
            <div className="spec-title">CC Available</div>
            <div className="subtitle">English, Korean, & Japanese</div>
          </div>
          <div className="experience-spec">
            <div className="spec-title">360 VR</div>
            <div className="subtitle">Immersive 3D</div>
          </div>
        </div>
        {trailer_stream_url && (
          <div>
            <div className="page-content">
              <div className="section-title">Series Trailer</div>
            </div>
            <div className="trailer-container center">
              {<ReactPlayer
                url={trailer_stream_url}
                controls={true}
                config={{
                  file: {
                    hlsOptions: {
                      forceHLS: true,
                      debug: false,
                      xhrSetup: function(xhr, url) {
                        xhr.withCredentials = true;
                      }
                    }
                  }
                }}
              />}
            </div>
          </div>
        )}
        <div className="page-content">
          <div className="section-title">Episodes</div>
        </div>
        <div className="card-index episode-index">
          {episodes
            .sort((a, b) => a.number - b.number)
            .map((episode) => (
              <EpisodeSeries episode={episode} key={episode.uuid} />
            ))}
        </div>
      </div>
    );
  }
  return null;
};

export default SeriesDetail;

import React from 'react';
import { Redirect } from 'react-router-dom';
import { GlobalContext } from '../services/context';
import CoursesIndex from '../components/CoursesIndex';
import KaraokeIndex from '../components/KaraokeIndex';
import SeriesIndex from '../components/SeriesIndex';
import MoviesIndex from '../components/MoviesIndex';

const Profile = () => {
  const { currentUser } = React.useContext(GlobalContext);

  if (!currentUser) return <Redirect to="/" />;

  return (
    <div className="profile">
      <div className="red-backdrop">
        <div className="profile-background">
          <div className="profile-overlay">
            <div className="page-content">
              <i className="profile-logo" />
              <div className="profile-name">{currentUser.name}</div>
              <div className="subtitle">A life long learner in pursuit of a better world.</div>
            </div>
          </div>
        </div>
      </div>
        <div>
          <div className="section-title page-content">Unlocked Content</div>
          <CoursesIndex isProfile={true} />
          <KaraokeIndex isProfile={true} />
          <SeriesIndex isProfile={true} />
          <MoviesIndex isProfile={true} />
        </div>
    </div>
  );
};

export default Profile;

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { BrowserLink } from './enums';

import ProtectedRoute from './routes/ProtectedRoute';
import AuthRoute from './routes/AuthRoute';
import FetchUser from './routes/FetchUser';

import ScrollToTop from './utils/ScrollToTop';
import NavDrawer from './components/NavDrawer';
import Header from './components/Header';
import Footer from './components/Footer';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Home from './pages/Home';
import Gps from './pages/Gps';
import CourseDetail from './pages/CourseDetail';
import MastersAcademy from './pages/MastersAcademy';
import TermsPrivacy from './pages/TermsAndPrivacy';
import SeriesDetail from './pages/SeriesDetail';
import Settings from './pages/Settings';
import Profile from './pages/Profile';
import Success from './pages/Success';
import ConiTV from './pages/ConiTV';
import Kpop from './pages/Kpop';

const App = () => {
  return (
    <FetchUser>
      <Router>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path={BrowserLink.mastersAcademy} component={MastersAcademy} />
          <Route exact path={BrowserLink.kpop} component={Kpop} />
          <Route exact path={BrowserLink.coniTV} component={ConiTV} />
          <Route exact path={BrowserLink.terms} component={TermsPrivacy} />
          <Route exact path={BrowserLink.privacy} component={TermsPrivacy} />
          <Route exact path={BrowserLink.success} component={Success} />
          <Route exact path={`${BrowserLink.courses}/:id`} component={CourseDetail} />
          <Route exact path={`${BrowserLink.series}/:id`} component={SeriesDetail} />
          <Route exact path={`${BrowserLink.orders}/:id`} component={CourseDetail} />
          <Route exact path={BrowserLink.gps} component={Gps} />
          <Route exact path="/" component={Home} />

          <AuthRoute exact path={BrowserLink.signUp} component={SignUp} />
          <AuthRoute exact path={BrowserLink.login} component={Login} />
          
          <ProtectedRoute exact path={BrowserLink.settings} component={Settings} />
          <ProtectedRoute exact path={BrowserLink.profile} component={Profile} />
        </Switch>
        <Footer />
        <NavDrawer />
      </Router>
    </FetchUser>
  );
};

export default App;

import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { format } from 'date-fns'

import { GlobalContext } from '../services/context';
import { Dates } from '../enums';

const Settings = () => {
  const context = useContext(GlobalContext)

  const [name, setName] = useState(context.currentUser.name)
  const [email, setEmail] = useState(context.currentUser.email)

  useEffect(() => {
    context.getUserSubscriptions();
    context.getUserOneTimePurchases();
  }, []);

  // TODO Form is disabled for now
  const update = (event) => {
    const { name, value } = event.target;
  };

  const submit = (event) => {
    event.preventDefault();
  };

  function buildSubscriptionListItems() {
    const subs = context.userSubscriptions;
    if (subs.length < 1) {
      return <span className="settings-list-item">You haven't purchased any subscriptions yet</span>
    }
    return subs.map(item => (
      <div key={item.uuid} className="flex">
        <span className="settings-list-item">{item.product.name}</span>
        <span className="settings-list-item ml-auto">{item.active ? 'Active' : 'Expired'}</span>
      </div>
    ))
  }

  function buildOneTimeListItems() {
    const items = context.userOneTimePurchases;
    if (items.length < 1) {
      return <span className="settings-list-item">You haven't made any one-time purchases yet</span>
    }
    return items.map(item => (
      <div key={item.uuid} className="flex">
        <span className="settings-list-item">{item.course.name}</span>
        <span className="settings-list-item ml-auto">{format(new Date(item.created_at), Dates.US_Simple)}</span>
      </div>
    ))
  }

  if (!context.currentUser) return <Redirect to="/" />;

  return (
    <div className="settings">
      <div className="container-640">
        <div className="page-title">Settings</div>
        <div className="section-title">My Information</div>
        <form className="settings-form">
          <label>
            First Name
            <input name="name" value={name} readOnly />
          </label>
          <label>
            Email Address
            <input name="email" type="email" value={email} readOnly />
          </label>
        </form>
        <div className="section-title">My Subscriptions</div>
        {buildSubscriptionListItems()}
        <div className="section-title">My One-Time Purchases</div>
        {buildOneTimeListItems()}
      </div>
    </div>
  );
}

export default Settings;

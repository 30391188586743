import React from 'react';
import { GlobalContext } from '../services/context';
import { fetchUser } from '../services/api';
import { SupportedLanguages } from '../enums/Localizations';
import axios from 'axios';

class FetchUser extends React.Component {
  static contextType = GlobalContext;

  state = { loaded: false };
  _isMounted = false;
  _previousContext = undefined;

  async componentDidMount() {
    this._isMounted = true;
    this._previousContext = this.context;
    const { currentUser, country, language } = this.context;
    if (currentUser && country && language) {
      this.loaded();
    } else {
      const axiosInstance = axios.create();
      axiosInstance.defaults.headers.common = {};
      axiosInstance.get('https://ipapi.co/json/').then( res => {
        const { country_code } = res.data;
        let language = window.navigator.userLanguage || window.navigator.language || '';
        language = language.slice(0, 2);
        const languageUpdate = SupportedLanguages.includes(language) ? language : 'en';
        this.context.updateLocale({country: country_code, language: languageUpdate});
      })
      .catch( err => {
        console.log('error retrieving locale data: ' + err);
        this.context.updateLocale({country: 'us', language: 'en'});
        // TODO: handle error
      })
      fetchUser().then((res) => {
        this.context.login(res.data.user);
        this.loaded();
      })
      .catch(() => {
        console.log('no current user');
        this.loaded();
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ( this._previousContext.currentUser !== this.context.currentUser || prevState !== this.state) {
      if (!this.state.loaded) {
        this.loaded();
      }
      this._previousContext = this.context;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loaded = () => {
    if (this._isMounted) {
      this.setState({ loaded: true });
    }
  };

  render() {
    return this.state.loaded ? this.props.children : null;
  }
}

export default FetchUser;

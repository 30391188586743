import React from 'react';
import { BrowserLink } from '../enums';
import { useHistory } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { GlobalContext } from '../services/context';
import { purchaseCourse } from '../services/api';

const Course = ( props ) => {
  const { course } = props;
  const [isRedirecting, setIsRedirecting] = React.useState(false);
  const stripe = useStripe();
  const history = useHistory();
  const context = React.useContext(GlobalContext);

  const sendToCheckout = (event) => {
    event.stopPropagation();
    setIsRedirecting(true);
    if (!context.currentUser) {
      history.push({
        pathname: BrowserLink.login,
        state: { experience: course, slide: true },
      });
    } else {
      purchaseCourse(course.uuid)
        .then((res) => {
          stripe.redirectToCheckout({ sessionId: res.data.id });
        })
        .catch((err) => {
          setIsRedirecting(false);
          // TODO: HANDLE ERROR;
          console.log(err);
        });
    }
  };

  const sendToExperience = () => {
    history.push({
      pathname: `${BrowserLink.courses}/${course.uuid}`,
      state: { course },
    });
  };

  return (
    <div role="a" className="experience card" onClick={sendToExperience}>
      <img src={course.coach.picture_url} />
      <div className="card-overlay" />
      <div className="card-details">
        <div className="card-title">{course.coach.name}</div>
        <div className="card-description">{course.name}</div>
        {/* {!course.is_unlocked && (
          <button className="get-button-small" disabled={isRedirecting} onClick={sendToCheckout}>
            Get
          </button>
        )} */}
      </div>
    </div>
  );
};
export default Course;

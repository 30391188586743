import 'core-js/stable';
import 'core-js/es/set';
import 'core-js/es/map';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './react/App';
import { GlobalProvider } from './react/services';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// eslint-disable-next-line no-undef
const stripePromise = loadStripe(process.env['STRIPE_PUBLISHABLE_KEY']);

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
  <GlobalProvider>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </GlobalProvider>, 
  document.body.appendChild(document.createElement('div')));
});
import React from 'react';
import { Link } from 'react-router-dom';
import { BrowserLink } from '../enums';
import { signUp } from '../services';
import { GlobalContext } from '../services/context';

class SignUp extends React.Component {
  state = {
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    error: false,
    close: false,
  };

  static contextType = GlobalContext;

  componentDidMount() {
    document.body.style.position = 'fixed';
  }

  update = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  close = () => {
    const { history } = this.props;
    document.body.style.position = '';
    this.setState({ close: true }, () => setTimeout(() => history.push('/'), 1000));
  };

  componentWillUnmount() {
    document.body.style.position = '';
  }

  submit = (event) => {
    event.preventDefault();
    const { name, email, password, password_confirmation } = this.state;
    const user = {
      user: {
        name,
        email,
        password,
        password_confirmation,
      },
    };
    signUp(user)
      .then((res) => {
        const { user } = res.data;
        this.context.login(user);
        this.close();
      })
      .catch(() => {
        // TODO: Handle error
        this.setState({ error: true });
      });
  };

  render() {
    const { name, email, password, password_confirmation, error, close } = this.state;
    return (
      <div className={'form-screen' + (close ? ' close' : '')}>
        <div className="form">
          <i className="form-logo in-form" onClick={this.close} />
          <div className="form-navigation">
            <span className="wide-screen">Already have an account?</span>
            <Link to={BrowserLink.login}>Log in</Link>
          </div>
          <div className="form-title">Sign Up</div>
          <div className="form-subtitle">
            You’re just moments away from immersing yourself in all out virtual reality.
          </div>
          <form className="form-sign-up" onSubmit={this.submit}>
            <label className="form-label">
              Name
              <input name="name" value={name} onChange={this.update} />
            </label>
            <label className="form-label">
              Email
              <input type="email" name="email" value={email} onChange={this.update} />
            </label>
            <label className="form-label">
              Password
              <input type="password" name="password" value={password} onChange={this.update} />
            </label>
            <label className="form-label">
              Confirm Password
              <input
                type="password"
                name="password_confirmation"
                value={password_confirmation}
                onChange={this.update}
              />
            </label>
            <div className="server-error">{error && <span>Email is already taken</span>}</div>
            <button type="submit" className="button submit-button">
              Create Account
            </button>
          </form>
        </div>
        <div className="form-background signup-background">
          <i className="form-logo" onClick={this.close} />
          <div className="form-copy-title">Welcome to learning.</div>
          <div className="form-copy">
            Join the masters, thousands of life-long learners, and level up your reality today.
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;

import * as React from 'react';
import {
  logout,
  defaultAxiosConfig,
  getAllCourses,
  getAllKaraoke,
  getAllSeries,
  getAllMovies, 
  getUserReceipts,
  getUserOneTimeReceipts,
} from './api';
import axios from 'axios';

export const GlobalContext = React.createContext();

export class GlobalProvider extends React.Component {
  login = (currentUser) => this.setState({ currentUser });

  logoutAction = () => {
    logout().then( () => {
      this.setState({ currentUser: undefined });
    })
    .catch( err => {
      // TODO: handle error
      console.log('error logging out: ' + err);
    })
  }

  updateCountry = (country) => {
    axios.defaults.headers.common = {
      ...defaultAxiosConfig,
      'Accept-Country': country
   }
    this.setState({ country });
  };

  updateLanguage = (language) => {
   axios.defaults.headers.common = {
      ...defaultAxiosConfig,
      'Accept-Language': language
   }
    this.setState({ language });
  }

  updateLocale = ({country, language}) => {
    axios.defaults.headers.common = {
      ...defaultAxiosConfig,
      'Accept-Language': language,
      'Accept-Country': country
   }
    this.setState({country, language})
  };

  updateCourses = (courses) => {
    this.setState({ courses });
  };

  updateEpisodes = (episodes) => {
    this.setState({ episodes });
  };
  
  updateKaraokes = (karaokes) => {
    this.setState({ karaokes });
  };
  
  updateSeries = (series) => {
    this.setState({ series });
  };
  
  updateSeriesEpisodes = (seriesEpisodes) => {
    this.setState({ seriesEpisodes });
  };
  
  updateMovies = (movies) => {
    this.setState({ movies });
  };

  updateUserSubscriptions = (receipts) => {
    this.setState({ userSubscriptions: receipts});
  };

  updateUserOneTimePurchases = (receipts) => {
    this.setState({ userOneTimePurchases: receipts });
  };

  showDrawer = (isShowing) => {
    this.setState({ drawer: isShowing });
  };

  getCourses = async (force) => {
    if (!force && this.state.courses.length > 0) {
      return this.state.courses;
    } else {
      try {
        const { data } = await getAllCourses();
        this.updateCourses(data.courses);
        return data.courses;
      } catch (error) {
        // TODO: Handle error
        console.log(error);
        return null;
      };
    }
  };
  
  getKaraokes = async (force) => {
    if (!force && this.state.karaokes.length > 0) {
      return this.state.karaokes;
    } else {
      try {
        const { data } = await getAllKaraoke();
        this.updateKaraokes(data.karaokes);
        return data.karaokes;
      } catch (error) {
        // TODO: Handle error
        console.log(error);
        return null;
      };
    }
  }
  
  getSeries = async (force) => {
    if (!force && this.state.series.length > 0) {
      return this.state.series;
    } else {
      try {
        const { data } = await getAllSeries();
        this.updateSeries(data.series);
        return data.series;
      } catch (error) {
        // TODO: Handle error
        console.log(error);
        return null;
      };
    }
  }
  
  getMovies = async (force) => {
    if (!force && this.state.movies.length > 0) {
      return this.state.movies;
    } else {
      try {
        const { data } = await getAllMovies();
        this.updateMovies(data.movies);
        return data.movies;
      } catch (error) {
        // TODO: Handle error
        console.log(error);
        return null;
      };
    }
  }

  getUserSubscriptions = async () => {
    try {
      const { data: { product_receipts } } = await getUserReceipts();
      this.updateUserSubscriptions(product_receipts);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  getUserOneTimePurchases = async () => {
    try {
      const { data: { course_receipts } } = await getUserOneTimeReceipts();    
      this.updateUserOneTimePurchases(course_receipts);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  subscriptionActiveStatus = (productId) => {
    return !!this.state.userSubscriptions.find(sub => sub.product.uuid === productId && sub.active)
  }
  
  state = {
    courses: [],
    episodes: [],
    products: [],
    karaokes: [],
    series: [],
    movies: [],
    seriesEpisodes: [],
    userSubscriptions: [],
    userOneTimePurchases: [],
    country: undefined,
    language: undefined,
    currentUser: undefined,
    drawer: false,
    login: this.login,
    logout: this.logoutAction,
    updateLocale: this.updateLocale,
    updateCountry: this.updateCountry,
    updateLanguage: this.updateLanguage,
    updateCourses: this.updateCourses,
    updateEpisodes: this.updateEpisodes,
    updateKaraokes: this.updateKaraokes,
    updateSeries: this.updateSeries,
    updateSeriesEpisodes: this.updateSeriesEpisodes,
    updateMovies: this.updateMovies,
    showDrawer: this.showDrawer,
    getCourses: this.getCourses,
    getKaraokes: this.getKaraokes,
    getSeries: this.getSeries,
    getMovies: this.getMovies,
    getUserSubscriptions: this.getUserSubscriptions,
    getUserOneTimePurchases: this.getUserOneTimePurchases,
    subscriptionActiveStatus: this.subscriptionActiveStatus,
  };

  render() {
    return <GlobalContext.Provider value={this.state}>{this.props.children}</GlobalContext.Provider>;
  }
}

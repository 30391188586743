import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BrowserLink } from '../enums';
import { GlobalContext } from '../services/context';

const ProfileMenu = ({ profileMenuRef, name, logout, close }) => {
  const context = useContext(GlobalContext);

  function handleLogout() {
    logout();
    close();
  };
  
  return (
    <div className="profile-menu" ref={profileMenuRef}>
      <div className="profile-menu-header">
        <i className="profile-logo" />
        <span>{name}</span>
      </div>
      <Link className="" to={BrowserLink.mastersAcademy} onClick={close}>
        Master Academy
      </Link>
      <Link className="" to={BrowserLink.kpop} onClick={close}>
        Kpop VRoom
      </Link>
      <Link className="" to={BrowserLink.coniTV} onClick={close}>
        ConiTV
      </Link>
      {context.currentUser && (
        <>
          <Link to={BrowserLink.profile} onClick={close}>
            Profile
          </Link>
          <Link to={BrowserLink.settings} onClick={close}>
            Settings
          </Link>
          <a onClick={handleLogout}>Log Out</a>
        </>
      )}
    </div>
  )
};

export default ProfileMenu;

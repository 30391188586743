export const Language = Object.freeze({
  Korean: 'ko',
  English: 'en',
  Japanese: 'ja',
})

export const LanguageByInitials = Object.freeze({
  ko: 'Korean',
  en: 'English',
  ja: 'Japanese',
})

// these correspond to image file names
export const Flags = Object.freeze({
  'ko': 'kr-flag',
  'en': 'us-flag',
  'ja': 'jp-flag',
})

export const IntlTags = Object.freeze({
  'en': 'en_US',
  'ko': 'ko_KR',
  'ja': 'ja_JP',
})

export const SupportedLanguages = (() => {
  const languageArray = Object.keys(Language).map( (lang) => {
    return Language[lang];
  })
  return languageArray;
})();


import React from 'react';
import { Link } from 'react-router-dom';
import { BrowserLink } from '../enums';
import Terms from '../components/Terms';
import Privacy from '../components/Privacy';

const NavLink = ({ pathname, to, children }) => (
  <Link to={to} className={pathname === to ? 'selected' : ''}>
    {children}
  </Link>
);

const Nav = ({ pathname }) => (
  <div className="terms-privacy-nav">
    <NavLink to={BrowserLink.terms} pathname={pathname}>
      Terms and conditions
    </NavLink>
    <NavLink to={BrowserLink.privacy} pathname={pathname}>
      Privacy Policy
    </NavLink>
  </div>
);

const TermsPrivacy = ({ location: { pathname } }) => (
  <div className="terms-privacy">
    <div className="page-content">
      <Nav pathname={pathname} />
      {pathname === BrowserLink.terms ? <Terms /> : <Privacy />}
    </div>
  </div>
);

export default TermsPrivacy;

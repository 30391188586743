import React from 'react';
import { BrowserLink, Language } from '../enums';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../services/context';
import LanguageSelect from './LanguageSelect';
import InstagramIcon from 'images/instagram-icon.svg'
import FacebookIcon from 'images/facebook-icon.svg'
import TwitterIcon from 'images/twitter-icon.svg'
import YoutubeIcon from 'images/youtube-icon.svg'

const FB_LINK = "https://www.facebook.com/OfficialVRLU/"
const YT_LINK_US = "https://www.youtube.com/officialvrlu"
const YT_LINK_KOREA = "https://www.youtube.com/channel/UCnJw_7rYUygn1neT3UbCcCg"
const TWITTER_LINK = "https://twitter.com/officialvrlu"
const INSTA_LINK_US = "https://www.instagram.com/officialvrlu/"
const INSTA_LINK_KOREA = "https://www.instagram.com/vrlukr/"

const Footer = () => {
  const context = React.useContext(GlobalContext);

  const INSTA_LINK = context.language === Language.Korean
    ? INSTA_LINK_KOREA
    : INSTA_LINK_US
  const YT_LINK = context.language === Language.Korean
    ? YT_LINK_KOREA
    : YT_LINK_US

  return (
    <div className="footer">
      <div className="page-content">
        <div>
          <i className="footer-logo" />
        </div>
        <div className="footer-columns">
          <div className="footer-column">
            <div>VRLU</div>
            <div>
              <Link to="/">Home</Link>
            </div>
            <div>
              <Link to={BrowserLink.mastersAcademy}>Master Academy</Link>
            </div>
            <div>
              <Link to={BrowserLink.kpop}>K-POP VRoom</Link>
            </div>
            <div>
              <Link to={BrowserLink.coniTV}>Coni TV</Link>
            </div>
          </div>
          <div className="footer-column">
            <div>Support</div>
            <div>
              <a href="http://www.myvrlu.com" target="_blank">
                Contact Us
              </a>
            </div>
          </div>
          <div className="footer-column">
            <div>Account</div>
            <div>
              <Link
                to={{
                  pathname: BrowserLink.login,
                  state: { slide: true },
                }}
              >
                Log In
              </Link>
            </div>
          </div>
          <div className="footer-column">
            <div>Terms & Privacy</div>
            <div>
              <Link to={BrowserLink.terms}>Terms of Service</Link>
            </div>
            <div>
              <Link to={BrowserLink.privacy}>Privacy Policy</Link>
            </div>
          </div>
        </div>
        <div className="footer-social">
          <a href={FB_LINK}>
            <img src={FacebookIcon} className="footer-social_icon"/>
          </a>
          <a href={INSTA_LINK}>
            <img src={InstagramIcon} className="footer-social_icon"/>
          </a>
          <a href={TWITTER_LINK}>
            <img src={TwitterIcon} className="footer-social_icon"/>
          </a>
          <a href={YT_LINK}>
            <img src={YoutubeIcon} className="footer-social_icon"/>
          </a>
        </div>
        <div className="copyright">
          <div className="hide-on-desktop">
            <LanguageSelect />
          </div>
          Copyright © 2020 VRLU LLC. All rights reserved.
          <div className="hide-on-mobile">
            <LanguageSelect />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

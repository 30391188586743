import React from 'react'
import { Link } from 'react-router-dom';
import { GlobalContext } from '../services/context';
import { BrowserLink } from '../enums';

const NavDrawer = () => {
  const context = React.useContext(GlobalContext);
  
  let drawerClasses = 'side-drawer'
  if(context.drawer) {
    drawerClasses = 'side-drawer open'
  }

  function closeDrawer() {
    context.showDrawer(false)
  }

  function logout() {
    context.showDrawer(false)
    context.logout()
  }

  return (
    <>
      <div className={drawerClasses}>
        <div className="">
          <div className="profile-menu-header flex items-center" onClick={closeDrawer}>
            <i className="profile-logo" />
            <span>{context.currentUser && context.currentUser.name}</span>
          </div>
          <Link className="" to={BrowserLink.mastersAcademy} onClick={closeDrawer}>
            Master Academy
          </Link>
          <Link className="" to={BrowserLink.kpop} onClick={closeDrawer}>
            Kpop VRoom
          </Link>
          <Link className="" to={BrowserLink.coniTV} onClick={closeDrawer}>
            ConiTV
          </Link>
          {context.currentUser && (
            <>
              <Link to={BrowserLink.profile} onClick={closeDrawer}>
                Profile
              </Link>
              <Link to={BrowserLink.settings} onClick={closeDrawer}>
                Settings
              </Link>
              <a onClick={logout}>Log Out</a>
            </>
          )}
        </div>
      </div>
      {context.drawer && <Backdrop closeDrawer={closeDrawer}/>}
    </>
  )
}

const Backdrop = ({ closeDrawer }) => {
  return(
    <div className="side-drawer-backdrop" onClick={closeDrawer} />
  )
}

export default NavDrawer
